/* ===========================
Index Of css

01. Veriables CSS
02. Normalize CSS
03. Header CSS
04. Hero CSS
05. About CSS
06. Testimonial CSS
07. Pricing CSS
08. Faq CSS
09. Blog CSS
10. Clients CSS
11. Apply Process CSS
12. Job Category CSS
13. Find Job CSS
14. Featured Job CSS
15. Call Action CSS
16. Job Details CSS
17. Post Job CSS
18. Resume CSS
19. Privacy Policy CSS
20. Add Resume CSS
21. Manage Resumes CSS
22. Job Alerts CSS
23. Manage Jobs CSS
24. Manage Applications CSS
25. Browse Resumes CSS
26. Bookmarked CSS
27. Notifications CSS
28. Change Password CSS
29. Responsive CSS
30. Contact CSS
31. Error CSS
32. Mail Success CSS
33. Modal CSS
34. Footer CSS

========================== */
/*======================================
    Variables
========================================*/
/*======================================
    Normalize CSS
========================================*/
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-style: normal;
  background-color: #7e8890;
  overflow-x: hidden;
  font-size: 14px;
}

p {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

.navbar-toggler:focus,
a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
}

span,
a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0px;
  color: #081828;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

/* Bread Crumbs */
.breadcrumbs {
  background-color: #081828;
  position: relative;
  padding: 70px 0;
  padding-top: 150px;
  background-image: url("../images/breadcrumb/breadcrumb-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  text-align: left;
}

.breadcrumbs.overlay::before {
  background-color: crimson;
  opacity: 0.8;
  z-index: -1;
}

.breadcrumbs .breadcrumbs-content {
  position: relative;
  text-align: left;
}

.breadcrumbs .breadcrumbs-content p {
  color: #fff;
  font-size: 14px;
  margin-top: 20px;
}

.breadcrumbs .breadcrumbs-content .page-title {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  position: relative;
  padding-bottom: 22px;
  line-height: 50px;
}

.breadcrumbs .breadcrumbs-content .page-title:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 80px;
  background: #fff;
}

.breadcrumbs .breadcrumbs-content .breadcrumb-nav {
  background: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
}

.breadcrumbs .breadcrumb-nav {
  text-align: left;
  margin-top: 50px;
}

.breadcrumbs .breadcrumb-nav li {
  display: inline-block;
}

.breadcrumbs .breadcrumb-nav li,
.breadcrumbs .breadcrumb-nav li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.breadcrumbs .breadcrumb-nav li a {
  padding-right: 15px;
  margin-right: 15px;
  position: relative;
}

.breadcrumbs .breadcrumb-nav li a:hover {
  text-decoration: underline;
}

.breadcrumbs .breadcrumb-nav li a:after {
  content: "";
  height: 80%;
  width: 2px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  right: 0;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

/* Section Title */
.section-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 300px;
  position: relative;
  z-index: 5;
}

.section-title span {
  text-transform: uppercase;
  background: crimson;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 12px;
  color: #fff;
  padding: 5px 18px;
  border-radius: 4px;
  line-height: 22px;
}

.section-title h2 {
  font-size: 35px;
  margin-bottom: 18px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
  padding-bottom: 14px;
}

.section-title h2:before {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background: crimson;
  content: "";
}

.section-title p {
  font-size: 14px;
  line-height: 24px;
}

.section-title.white-text h2 {
  color: #fff;
}

.section-title.white-text h2::before {
  background-color: #fff;
}

.section-title.white-text span {
  color: #fff;
}

.section-title.white-text p {
  color: #fff;
}

.section-title.align-right {
  padding: 0;
  padding-left: 600px;
}

.section-title.align-right h2:before {
  display: none;
}

.section-title.align-right h2:after {
  position: absolute;
  right: 0;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background: crimson;
  content: "";
}

.section-title.align-left {
  padding: 0;
  padding-right: 600px;
}

.section-title.align-left h2:before {
  left: 0;
  margin-left: 0;
}

/* One Click Scrool Top Button*/
.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: crimson;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  color: #fff !important;
  border-radius: 0;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
}

.scroll-top:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  background-color: #081828;
}

/* Overlay */
.overlay {
  position: relative;
}

.overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: #081828;
  content: "";
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 5;
}

/* Pagination CSS */
.pagination {
  text-align: left;
  margin: 40px 0 0 0;
  display: block;
}

.pagination.center {
  text-align: center;
}

.pagination.right {
  text-align: right;
}

.pagination.left {
  text-align: left;
}

.pagination .pagination-list {
  display: inline-block;
  overflow: hidden;
}

.pagination .pagination-list li {
  margin-right: 5px;
  display: inline-block;
}

.pagination .pagination-list li:last-child {
  margin-right: 0px;
}

.pagination .pagination-list li a {
  background: #fff;
  color: #081828;
  font-weight: 500;
  font-size: 13px;
  border-radius: 0;
  line-height: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #eee;
}

.pagination .pagination-list li.active a,
.pagination .pagination-list li:hover a {
  background: crimson;
  color: #fff;
  border-color: transparent;
}

.pagination .pagination-list li a i {
  font-size: 13px;
}

.blog-grids.pagination {
  margin-top: 50px;
  text-align: center;
}

.button .btn {
  display: inline-block;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 30px;
  background-color: crimson;
  color: #fff;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 4px;
}

.button .btn:hover {
  background-color: #081828;
  color: #fff;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
}

.button .btn-alt {
  background-color: #081828;
  color: #fff;
}

.button .btn-alt:hover {
  background-color: crimson;
  color: #fff;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

#loading-area {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 999999999;
  background-image: url(../images/loading.svg);
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
}

/*======================================
	Header CSS
========================================*/
/*===== NAVBAR =====*/
.header.style4 .navbar-area {
  background-color: #fff;
  border: none;
}

.navbar-area {
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background-color: transparent;
  position: absolute;
  border-bottom: 1px solid #e6e6e6;
}

.other-page .navbar-area {
  background-color: #fff;
  border: none;
  float: right;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  top: 0;
}

.sticky .navbar .navbar-nav .nav-item a {
  color: #333;
}

.sticky .navbar .navbar-nav .nav-item a.active {
  color: crimson;
}

.sticky .navbar .navbar-toggler .toggler-icon {
  background: #333;
}

.navbar {
  padding: 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    padding: 17px 0;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 17px 0;
  }
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  width: 150px;
}

.navbar-toggler {
  padding: 0;
  background-color: crimson;
  padding: 5px 8px !important;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.navbar-toggler .toggler-icon {
  background-color: #fff !important;
}

.navbar-toggler:hover {
  background-color: #081828 !important;
}

.navbar-toggler:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-toggler .toggler-icon {
  width: 22px;
  height: 2px;
  background-color: #333;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 12px;
  }
}
@media (max-width: 991px) {
  /* .open-nav{
    display: block !important;
  } */
  .navbar-nav{
    margin-left: unset;
    margin-top: 150px;
  }
}
@media (min-width: 992px) {
  .navbar-nav{
    margin-left: auto;
    margin-top: unset;
  }
}
@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(29, 42, 93, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(29, 42, 93, 0.1);
    padding: 5px 12px;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 36px;
}

.navbar-nav .nav-item a {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 30px 0;
  position: relative;
  text-transform: capitalize;
  /* text-align: left; */
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item a {
    display: inline-block;
    padding: 6px 0px;
    color: #5e678c;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item a {
    display: inline-block;
    padding: 6px 0px;
    color: #5e678c;
  }
}

.navbar-nav .nav-item a::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 3px;
  background: crimson;
  z-index: -1;
  opacity: 0;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 5;
}

.navbar-nav .nav-item a::before {
  width: 0%;
  left: 0;
}

.navbar-nav .nav-item a::after {
  width: 20%;
  right: 0;
}

.navbar-nav .nav-item a:hover,
.navbar-nav .nav-item a.active {
  color: crimson;
}

.navbar-nav .nav-item:hover a:before,
.navbar-nav .nav-item a.active::before {
  opacity: 1;
  width: 100%;
}

.header .navbar-nav li .sub-menu li a.active {
  background-color: crimson !important;
  color: #fff !important;
}

.navbar-nav .nav-item a i {
  display: inline-block;
  margin-left: 4px;
  font-size: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item a:hover::after,
  .navbar-nav .nav-item a:hover::before,
  .navbar-nav .nav-item a.active::after,
  .navbar-nav .nav-item a.active::before {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item a:hover::after,
  .navbar-nav .nav-item a:hover::before,
  .navbar-nav .nav-item a.active::after,
  .navbar-nav .nav-item a.active::before {
    opacity: 1;
  }
}

.navbar-nav .nav-item:hover .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

.navbar-nav .sub-nav-toggler {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .sub-nav-toggler {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: #222;
    font-size: 18px;
    border: 0;
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 767px) {
  .navbar-nav .sub-nav-toggler {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: #222;
    font-size: 18px;
    border: 0;
    width: 30px;
    height: 30px;
  }
}

.navbar-nav .sub-nav-toggler span {
  width: 8px;
  height: 8px;
  border-left: 1px solid #222;
  border-bottom: 1px solid #222;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: -5px;
}

.header {
  position: relative;
}

/* Header Button */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header .button {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .header .button {
    display: none !important;
  }
}

.header .button {
  display: inherit !important;
  margin-left: 60px;
}

.header .button .login {
  display: inherit;
  margin-right: 30px;
  padding: 0;
  height: 45px;
  line-height: 45px;
  border-radius: 4px;
  color: crimson;
  font-weight: 500;
  font-size: 14px;
}

.header .button .login i {
  display: inherit;
  margin-right: 5px;
  font-size: 14px;
  line-height: 45px;
  margin-right: 8px;
}

.header .button .login:hover {
  color: #081828;
  border-color: transparent;
}

.header .button .btn {
  color: #fff;
  padding: 0 !important;
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background: crimson;
  border: none;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 10px;
  height: 45px;
  line-height: 42px;
  width: 130px;
}

.header .button .btn:hover {
  color: #fff;
  background-color: #081828;
  border-color: transparent;
  -webkit-transform: none;
  transform: none;
}

/* Dropdown Menu */
.header .navbar-nav li .sub-menu {
  background: #fff;
  width: 220px;
  text-align: left;
  position: absolute;
  top: 100%;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  padding: 15px;
  left: 0;
  margin: 0;
  -webkit-box-shadow: 1px 4px 12px rgba(51, 51, 51, 0.25);
  box-shadow: 0px 13px 20px rgba(153, 153, 153, 0.06);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 9999999;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-transform: scaleY(0.2);
  transform: scaleY(0.2);
  border-radius: 0 0 3px 3px;
}

.header .navbar-nav li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.header .navbar-nav li .sub-menu li {
  float: none;
  margin: 0;
  display: block;
  margin-bottom: 6px;
}

.header .navbar-nav li .sub-menu li:last-child {
  margin-bottom: 0px;
}

.header .navbar-nav li .sub-menu li:last-child {
  border: none;
}

.header .navbar-nav li .sub-menu li a {
  padding: 10px 15px;
  color: #666;
  display: block;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  background: transparent;
  border-radius: 3px;
  font-weight: 400;
}

.header .navbar-nav li .sub-menu li a:before {
  display: none;
}

.header .navbar-nav li .sub-menu li a:hover:before {
  opacity: 1;
  visibility: visible;
}

.header .navbar-nav li .sub-menu li:last-child a {
  border-bottom: 0px;
}

.header .navbar-nav li .sub-menu li:hover a {
  color: #fff;
  background: crimson;
}

.header .navbar-nav li .sub-menu li .sub-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.header.index2 .sticky .nav-item a {
  color: #081828 !important;
}

.header.index2 .sticky .nav-item a:hover {
  color: crimson !important;
}

.header.index2 .sticky .nav-item a.active {
  color: crimson !important;
}

.header.index2 .navbar-area.sticky .navbar-nav li .sub-menu li a.active {
  color: #fff !important;
}

.header.index2 .logo {
  position: relative;
  margin: 0;
  padding: 0;
  top: -16px;
}

.header.index2 .logo img {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header.index2 .logo .logo1 {
  opacity: 0;
  visibility: hidden;
}

.header.index2 .navbar-nav .nav-item a {
  color: #fff;
}

.header.index2 .navbar-nav .nav-item a::before {
  background-color: #fff;
}

.header.index2 .button .btn:hover {
  background-color: #fff;
  color: crimson;
}

/* Index 2 */
.header.index2 .sticky .logo .logo1 {
  opacity: 1;
  visibility: visible;
}

.header.index2 .sticky .logo .logo2 {
  opacity: 0;
  visibility: hidden;
}

.header.index2 .sticky .nav-item a::before {
  background-color: crimson;
}

.header.index2 .navbar-nav li .sub-menu {
  border-radius: 3px;
}

.header.index2 .navbar-nav .sub-menu li a {
  color: #081828;
}

.header.index2 .navbar-nav .sub-menu li a:hover {
  color: #fff !important;
}

/* Index 4 */
.header.index4 .navbar-area {
  position: absolute;
}

.header.index4 .sticky {
  position: fixed;
  top: 0;
}

/*======================================
    Hero Area CSS
========================================*/
.hero-area {
  position: relative;
  background: #f6f9fc;
  overflow: hidden;
}

.hero-area.style2 {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero-area.style2 .home-slider {
  position: relative;
  height: 700px;
}

.hero-area.style2 .hero-inner {
  height: auto;
}

.hero-area.style2 .hero-inner .hero-text {
  margin-top: 220px;
}

.hero-area.style2 .hero-image {
  text-align: center;
  position: relative;
  bottom: -140px;
  right: 0;
}

.hero-area.style2 .tns-controls {
  width: 100%;
}

.hero-area.style2 .tns-controls button {
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 2;
  color: #fff;
  font-size: 17px;
  background: crimson;
  border: none;
  padding: 2px 5px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  z-index: 99;
  top: 50%;
  margin-top: -25px;
}

.hero-area.style2 .tns-controls button:hover {
  background-color: #081828;
  color: #fff;
}

.hero-area.style2 .tns-controls button:first-child {
  border-radius: 50%;
  left: 30px;
}

.hero-area.style2 .tns-controls button:last-child {
  border-radius: 50%;
  right: 30px;
}

.hero-area.style3 .hero-inner {
  height: 700px;
}

.hero-area.style3 .hero-inner .hero-text {
  margin-top: 190px;
}

.hero-area.style3 .home-search {
  margin-top: 180px;
  padding: 40px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
}

.hero-area.style3 .home-search .button {
  margin-top: 20px;
}

.hero-area.style3 .home-search .button .btn {
  width: 100%;
}

.hero-area.style3 .home-search .form-group {
  margin-bottom: 15px;
}

.hero-area.style3 .home-search .form-group label {
  color: #081828;
  display: block;
  margin-bottom: 4px;
}

.hero-area.style3 .home-search .form-group input {
  height: 50px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0px 20px;
  font-size: 15px;
  font-weight: 400;
}

.form-control{
  padding: 10px !important;
}

.hero-area.style3 .home-search .form-group .form-control {
  height: 50px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0px 20px;
  font-size: 15px;
  font-weight: 400;
}

.hero-area.style3 .home-search .form-group .form-control option {
  font-weight: 400 !important;
}

.hero-area.style4 {
  overflow: visible;
  background-image: url("../images/hero/home-page4.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.hero-area.style4::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: crimson;
  opacity: 0.9;
}

.hero-area.style4::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/hero/small-patern.png");
  opacity: 0.2;
}

.hero-area.style4 .hero-inner {
  height: auto !important;
}

.hero-area.style4 .hero-inner .hero-text {
  margin-top: 190px;
  padding: 0;
  text-align: center;
  padding: 0px 98px;
}

.hero-area.style4 .hero-inner .hero-text h1 {
  color: #fff;
}

.hero-area.style4 .hero-inner .hero-text p {
  color: #fff;
}

.hero-area.style4 .hero-inner .hero-text .button .btn {
  background-color: #fff;
  color: crimson;
}

.hero-area.style4 .hero-inner .hero-text .button .btn:hover {
  background-color: #081828;
  color: #fff;
}

.hero-area.style4 .hero-inner .hero-text .button .btn-alt {
  background-color: #081828;
  color: #fff;
}

.hero-area.style4 .hero-inner .hero-text .button .btn-alt:hover {
  background-color: #fff;
  color: crimson;
}

.hero-area.style4 .job-search-wrap-two {
  padding: 40px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  position: relative;
  top: 102px;
}

.hero-area .hero-inner {
  height: 800px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 3;
}

.hero-area .hero-text {
  float: none;
  margin-top: 230px;
  padding-right: 50px;
}

.hero-area .hero-text h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 25px;
}

.hero-area .hero-text h1 span {
  font-weight: 300;
}

.hero-area .hero-text p {
  font-size: 15px;
  line-height: 24px;
}

.hero-area .hero-text .button {
  margin-top: 35px;
}

.hero-area .hero-text .button .btn {
  margin-right: 10px;
}

.hero-area .hero-text .button .btn:hover {
  color: #fff;
}

.hero-area .hero-text .button .btn:last-child {
  margin-right: 0px;
}

.hero-area .hero-video-head {
  margin-top: 160px;
}

.hero-area .hero-video-head .video-inner {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero-area .hero-video-head .video-inner::before {
  position: absolute;
  content: "";
  right: -50px;
  bottom: -90px;
  height: 300px;
  width: 300px;
  background-image: url(../images/hero/pattern.png);
  background-repeat: no-repeat;
  z-index: -1;
}

.hero-area .hero-video-head .video-inner img {
  width: 100%;
  border-radius: 10px 100px 10px 100px;
}

.hero-area .hero-video-head .video-inner .hero-video {
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  color: #fff;
  background-color: crimson;
  font-size: 20px;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 99;
  padding-left: 5px;
  -webkit-box-shadow: 0px 0px 30px crimsonb3;
  box-shadow: 0px 0px 30px crimsonb3;
}

.hero-area .hero-video-head .video-inner .hero-video:hover {
  background-color: #fff;
  color: crimson;
}

.hero-area .video-inner .waves-block .waves {
  position: absolute;
  width: 150px;
  height: 150px;
  background: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  -webkit-animation: waves 5s ease-in-out infinite;
  animation: waves 4s ease-in-out infinite;
  left: 50%;
  margin-left: -75px;
  top: 50%;
  margin-top: -75px;
  z-index: 1;
}

.hero-area .video-inner .waves-block .wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.hero-area .video-inner .waves-block .wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.hero-area .video-inner .waves-block .wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* Video Animations */
@-webkit-keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-webkit-keyframes lineanim {
  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  50.1% {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
}

@keyframes lineanim {
  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  50.1% {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
  }
}

.job-search-wrap-two .job-search-form {
  border: 1px solid #e2e4ea;
  background: #fff;
  border-radius: 4px;
}

.job-search-wrap-two .job-search-form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 0;
}

.job-search-wrap-two .job-search-form form .single-field-item {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 37%;
  flex: 0 0 37%;
  max-width: 37%;
  padding: 0 30px;
  height: 100%;
  margin-bottom: 0;
}

.job-search-wrap-two .job-search-form form .single-field-item label {
  display: block;
  font-size: 15px;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
}

.job-search-wrap-two .job-search-form form .single-field-item input {
  width: 100%;
  border: none;
  color: #999;
  font-size: 15px;
  font-weight: 400;
  padding: 0;
  height: auto;
  line-height: inherit;
}

.job-search-wrap-two .job-search-form form .submit-btn {
  width: 100%;
}

.job-search-wrap-two .job-search-form form .submit-btn .btn {
  background-color: crimson;
  color: #fff;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.job-search-wrap-two .job-search-form form .submit-btn .btn:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  background-color: #081828;
  color: #fff;
  border-color: transparent;
}

.job-search-wrap-two
  .job-search-form
  form
  .single-field-item:first-child::after {
  position: absolute;
  content: "";
  height: 70%;
  width: 1px;
  background-color: #e2e4ea;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.keywords {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.keywords ul li {
  display: inline-block;
}

.keywords ul li a {
  font-size: 13px;
  color: #999;
  padding: 0 7px;
  border: 1px solid #e4e5ec;
  border-radius: 3px;
  margin-bottom: 4px;
  line-height: 22px;
  margin-right: 0;
}

.keywords ul li a:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  background-color: crimson;
  color: #fff;
  border-color: transparent;
}

.keywords ul li a {
  font-size: 13px;
  color: #999;
  padding: 0 7px;
  border: 1px solid #e4e5ec;
  border-radius: 3px;
  margin-bottom: 4px;
  line-height: 22px;
  margin-right: 0;
}

.keywords.style-two .title {
  font-size: 14px;
  color: crimson;
  margin-right: 10px;
  line-height: 22px;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  position: relative;
  top: -2px;
}

/*======================================
    About Us CSS
========================================*/
.about-us .content-left {
  position: relative;
  padding-right: 60px;
}

.about-us .content-left .single-img {
  width: 100%;
  border-radius: 5px;
}

.about-us .content-left .single-img.minus-margin {
  position: relative;
  top: -20px;
}

.about-us .content-left::before {
  position: absolute;
  content: "";
  right: 4px;
  bottom: -36px;
  height: 200px;
  width: 200px;
  background-image: url(../images/hero/pattern.png);
  background-repeat: no-repeat;
  z-index: -1;
}

.about-us .content-left .media-body {
  padding: 40px 30px;
  padding-bottom: 40px;
  border-radius: 5px;
  background-color: crimson;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 48px;
}

.about-us .content-left .media-body i {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  color: #fff;
  background-color: #ffffff45;
  border-radius: 5px;
  display: block;
  margin-bottom: 20px;
}

.about-us .content-left .media-body h6 {
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  color: #fff;
}

.about-us .content-left .media-body p {
  color: #fff;
}

.about-us .content-right {
  padding-right: 70px;
  padding-left: 30px;
}

.about-us .content-right h2 {
  font-size: 32px;
  margin-bottom: 50px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
}

.about-us .content-right .single-list {
  position: relative;
  padding-left: 80px;
  margin-bottom: 50px;
}

.about-us .content-right .single-list:last-child {
  margin-bottom: 0;
}

.about-us .content-right .single-list i {
  font-size: 20px;
  height: 55px;
  width: 55px;
  line-height: 55px;
  text-align: center;
  display: inline-block;
  background: crimson1c;
  color: crimson;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 3px;
}

.about-us .content-right .single-list .list-bod h5 {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  color: #081828;
}

.about-us .content-right .single-list .list-bod p {
  font-size: 14px;
  line-height: 24px;
}

/*======================================
    Testimonial CSS
========================================*/
.testimonials {
  background-color: #081828;
  padding-top: 100px;
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.testimonials .patern1 {
  position: absolute;
  top: 245px;
  right: 8%;
  width: 174px;
  height: 174px;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.6;
}

.testimonials .patern2 {
  position: absolute;
  top: -49px;
  left: -75px;
  width: 174px;
  height: 174px;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.6;
}

.testimonials .section-title {
  padding: 0;
}

.testimonials .section-title h2 {
  color: #fff;
}

.testimonials .section-title p {
  color: #fff;
}

.testimonials .testimonial-inner-head {
  padding-bottom: 100px;
}

.testimonials .testimonial-inner {
  position: relative;
}

.testimonials .testimonial-right {
  text-align: right;
}

.testimonials .single-testimonial {
  height: 100%;
  width: 100%;
  position: relative;
}

.testimonials .single-testimonial .quote i {
  font-size: 30px;
  color: #fff;
}

.testimonials .single-testimonial p {
  font-size: 15px;
  color: #fff;
}

.testimonials .single-testimonial .bottom {
  position: relative;
  margin-top: 40px;
}

.testimonials .single-testimonial .bottom .clien-image {
  margin-right: 20px;
}

.testimonials .single-testimonial .bottom .clien-image img {
  height: 85px;
  width: 85px;
  border-radius: 100%;
  margin-right: 22px;
  float: left;
  border: 6px solid #ffffff21;
}

.testimonials .single-testimonial .bottom .name {
  font-size: 17px;
  position: relative;
  top: 20px;
  color: #fff;
}

.testimonials .single-testimonial .bottom .name span {
  font-size: 13px;
  display: block;
  margin-top: 5px;
  color: #888;
  font-weight: 400;
}

.testimonials .tns-controls {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.testimonials .tns-controls button {
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 2;
  color: #fff;
  font-size: 20px;
  background: #fff;
  border: 1px solid #f4eefb;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: transparent;
  margin: 0px 5px;
  line-height: 45px;
}

.testimonials .tns-controls button:hover {
  background-color: #fff;
  border-color: transparent;
  color: crimson;
}

/*=============================
	Pricing Table CSS
===============================*/
.pricing-table {
  background: #f6f9fc;
  position: relative;
}

.pricing-table .single-table {
  background: #fff;
  text-align: left;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 55px 50px;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  overflow: hidden;
  border-radius: 5px;
}

.pricing-table .single-table .table-head {
  position: relative;
  padding-bottom: 18px;
  border-bottom: 1px solid #edeff2;
}

.pricing-table .single-table .table-head::before {
  position: absolute;
  content: "";
  background: #e4e8ed;
  width: 15px;
  height: 15px;
  right: 0px;
  bottom: -7px;
  border-radius: 5px;
}

.pricing-table .single-table .table-head .title {
  font-size: 18px;
  line-height: 26px;
  color: #081828;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 19px;
}

.pricing-table .single-table .table-head .amount {
  font-size: 40px;
  line-height: 56px;
  font-weight: 700;
  color: #081828;
}

.pricing-table .single-table .table-head .duration {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-left: 7px;
}

.pricing-table .single-table .table-list {
  position: relative;
  display: block;
  margin-top: 35px;
}

.pricing-table .single-table .table-list li {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 26px;
  color: #081828;
  font-weight: 400;
  padding-left: 30px;
  margin-bottom: 15px;
}

.pricing-table .single-table .table-list li:last-child {
  margin-bottom: 0;
}

.pricing-table .single-table .table-list li::before {
  position: absolute;
  content: "\ea55";
  font-family: "lineIcons";
  font-size: 7px;
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  border-radius: 3px;
  left: 0px;
  top: 6px;
  background-color: crimson;
}

.pricing-table .single-table .button {
  margin-top: 40px;
}

.pricing-table .single-table .button .btn {
  font-size: 13px;
}

/*======================================
    Faq CSS
========================================*/
.faq-area {
  background-color: #f6f9fc;
  padding-bottom: 70px;
}

.faq-area .cercle span {
  background-color: crimson;
  color: #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  position: relative;
  z-index: 5;
}

.faq-area .content {
  margin-left: 20px;
}

.faq-area .single-faq {
  margin-bottom: 30px;
  background: #fff;
  padding: 40px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.faq-area .heading {
  font-size: 20px;
  color: #081828;
  display: block;
  margin-bottom: 20px;
}

.faq-area .text {
  font-size: 14px;
  margin-bottom: 20px;
}

.faq-area .text:last-child {
  margin-bottom: 0;
}

.index2.faq-area {
  padding-top: 100px;
}

/*======================================
    Blog CSS
========================================*/
.latest-news-area .single-news {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  margin-top: 30px;
  border-radius: 5px;
  position: relative;
  border-radius: 6px;
}

.latest-news-area .single-news .image {
  position: relative;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}

.latest-news-area .single-news .image img {
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.latest-news-area .single-news .content-body {
  background-color: #fff;
  padding: 20px 30px 30px 30px;
  border-radius: 0 0 6px 6px;
}

.latest-news-area .single-news .content-body .meta-details ul {
  display: block;
  margin-top: 20px;
}

.latest-news-area .single-news .content-body .meta-details ul li {
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 3px;
}

.latest-news-area .single-news .content-body .meta-details ul li:last-child {
  margin: 0;
}

.latest-news-area .single-news .content-body .meta-details ul li i {
  font-size: 13px;
  display: inline-block;
  margin-right: 3px;
}

.latest-news-area .single-news .content-body .meta-details ul li a {
  display: inline-block;
  font-size: 12px;
  position: relative;
  background: crimson14;
  color: crimson;
  padding: 5px 10px;
  border-radius: 3px;
  text-transform: capitalize;
}

.latest-news-area .single-news .content-body .meta-details ul li a:hover {
  background-color: crimson;
  color: #fff;
}

.latest-news-area .single-news .content-body .title {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 18px;
  padding-bottom: 15px;
  position: relative;
  font-size: 19px;
  font-weight: 500;
}

.latest-news-area .single-news .content-body .title::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #eee;
}

.latest-news-area .single-news .content-body .title a {
  color: #081828;
  display: inline-block;
}

.latest-news-area .single-news .content-body .title a:hover {
  color: crimson;
}

.latest-news-area .single-news .content-body p {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

.latest-news-area .single-news .content-body .button {
  display: block;
  margin-top: 20px;
}

.latest-news-area .single-news:hover .image .thumb {
  -webkit-transform: scale(1.1) rotate(1deg);
  transform: scale(1.1) rotate(1deg);
}

.blog-list {
  background: #f6f9fc;
}

.blog-list .single-news {
  margin: 0;
}

.blog-list .single-news {
  margin-bottom: 40px;
}

/* News Details */
.blog-single {
  background: #f6f9fc;
}

.blog-single .single-inner {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.blog-single .post-thumbnils {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.blog-single .post-thumbnils img {
  width: 100%;
}

.blog-single .post-thumbnils .author {
  display: inline-block;
  padding: 6px 15px 6px 7px;
  border-radius: 30px;
  background-color: crimson;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.blog-single .post-thumbnils .author img {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: inline-block;
}

.blog-single .post-thumbnils .author span {
  color: #fff;
  display: inline-block;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
}

.post-details .detail-inner {
  background-color: #fff;
  padding: 0;
  margin-top: 40px;
}

.post-details .post-thumbnils {
  margin-bottom: 30px;
}

.post-details .post-title {
  font-weight: 700;
  margin-bottom: 25px;
  line-height: 32px;
}

.post-details .post-title a {
  font-size: 25px;
  font-weight: 600;
}

.post-details .post-title a:hover {
  color: crimson;
}

ul.custom-flex {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.post-details .post-meta li {
  font-weight: 500;
  font-size: 15px;
  margin-right: 25px;
}

.post-details .post-meta li a i {
  font-weight: 400;
  margin-right: 3px;
}

.post-details .post-meta li a:hover {
  color: crimson;
}

.post-details p {
  font-size: 15px;
  margin: 30px 0;
}

.post-details h3 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 28px;
}

.post-details p:last-child {
  margin-bottom: 0;
}

blockquote {
  position: relative;
  color: #fff;
  font-weight: 400;
  clear: both;
  z-index: 1;
  margin: 40px 0;
  text-align: left;
  padding: 40px;
  background-color: crimson;
  border-radius: 5px;
  overflow: hidden;
}

blockquote .icon i {
  font-size: 40px;
  color: #fff;
  display: block;
  margin-bottom: 20px;
}

blockquote h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

blockquote span {
  font-size: 13px;
  display: block;
  margin-top: 20px;
}

blockquote .shape {
  position: absolute;
  right: -90px;
  top: -91px;
  z-index: -1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.post-details .list {
  margin: 40px;
}

.post-details .list li {
  color: #888;
  font-weight: 400;
  position: relative;
  padding-left: 40px;
  display: block;
  margin-bottom: 20px;
  position: relative;
  font-size: 15px;
}

.post-details .list li i {
  display: block;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 10px;
  color: #fff;
  background-color: crimson;
  position: absolute;
  left: 0;
  top: -1px;
  text-align: center;
  border-radius: 3px;
}

.post-details .list li:last-child {
  margin: 0;
}

.popular-tag-widget .tag-title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 17px;
}

.post-tags-media .share-title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 17px;
}

.post-details .post-tags-media .post-social-media ul {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.post-details .post-tags-media .post-social-media ul > li > a {
  font-size: 15px;
  color: #999;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: 1px solid #eee;
  border-radius: 4px;
  text-align: center;
  margin-left: 10px;
}

.post-details .post-tags-media .post-social-media ul > li > a:hover {
  background-color: crimson;
  color: #fff;
  border-color: transparent;
}

.post-details .post-tags-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.post-details .post-meta li {
  font-weight: 500;
  margin-right: 25px;
}

.post-details .post-meta li a {
  font-size: 13px;
  font-weight: 400;
  font-weight: 500;
  color: #081828;
}

.post-details .post-meta li a i {
  font-weight: 400;
  margin-right: 5px;
  color: crimson;
}

.post-details > p {
  font-size: 14px;
}

.post-details .post-image {
  margin: 40px 0 20px 0;
  width: 100%;
}

.post-details .post-image img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 5px;
}

.post-details > ul > li {
  font-weight: 500;
}

.post-details .post-tags-media .post-tags .tags a {
  color: #333;
  background: transparent;
}

.post-details .post-tags-media .post-tags .tags a:hover {
  color: #fff;
  background-color: crimson;
}

.post-details .post-tags-media .post-social-media {
  text-align: right;
}

.post-details .post-tags-media .post-social-media ul {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.detail-post-navigation {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  padding: 30px;
  border: 1px solid #eee;
}

/*comments*/
.post-comments {
  background-color: #fff;
  padding: 40px;
  margin-top: 40px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.comment-title {
  font-size: 22px !important;
  font-weight: 500 !important;
  margin-bottom: 40px !important;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
}

.comment-title span {
  background-color: white;
  padding-right: 15px;
}

.comment-title::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #eee;
  content: "";
  z-index: -1;
}

.comment-reply-title {
  font-size: 22px !important;
  font-weight: 500 !important;
  margin-bottom: 40px !important;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
}

.comment-reply-title span {
  background-color: white;
  padding-right: 15px;
}

.comment-reply-title::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #eee;
  content: "";
  z-index: -1;
}

.post-comments .comments-list li {
  padding-left: 130px;
  position: relative;
  font-size: 14px;
}

.post-comments .comments-list li .comment-img {
  position: absolute;
  left: 0;
  width: 100px;
  height: 100px;
}

.post-comments .comments-list li .comment-img img {
  max-width: 100px;
  max-height: 100px;
}

.post-comments .comments-list li .comment-desc .desc-top {
  margin-bottom: 20px;
  position: relative;
  display: block;
}

.post-comments .comments-list li .comment-desc .desc-top h6 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 500;
}

.post-comments .comments-list li .comment-desc .desc-top h6 .saved {
  color: crimson;
  font-size: 14px;
  margin-left: 10px;
}

.post-comments .comments-list li .comment-desc .desc-top span.date {
  font-size: 14px;
  font-weight: 400;
}

.post-comments .comments-list li .comment-desc .desc-top .reply-link {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 10px 22px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  z-index: 2;
  border-radius: 3px;
  border: 1px solid #eee;
  color: crimson;
}

.post-comments .comments-list li .comment-desc .desc-top .reply-link:hover {
  background-color: crimson;
  color: #fff;
  border-color: transparent;
}

.post-comments .comments-list li .comment-desc .desc-top .reply-link i {
  margin-right: 5px;
}

.post-comments .comments-list li .comment-desc p {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 14px;
}

.post-comments .comments-list li.children {
  margin-left: 130px;
}

.post-comments .comments-list li:not(:first-child) {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px dashed #eee;
}

/*Comment form*/
.comment-form {
  background-color: #fff;
  padding: 40px;
  margin-top: 40px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.comment-form form .form-box {
  position: relative;
}

.comment-form form .form-box .icon {
  position: absolute;
  top: 17px;
  right: 25px;
  font-size: 16px;
}

.comment-form form .form-box .form-control-custom {
  border: none;
  background: #fff;
  font-size: 14px;
  color: #081828;
  padding: 0 25px;
  font-weight: 500;
  height: 55px;
  border: 1px solid #eee;
  margin-bottom: 25px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
}

.comment-form form .form-box textarea.form-control-custom {
  height: 200px;
  padding: 25px;
}

.comment-form form .form-box .form-control-custom::-webkit-input-placeholder {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  padding: 25px;
}

.comment-form form .form-box .form-control-custom:-ms-input-placeholder {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  padding: 25px;
}

.comment-form form .form-box .form-control-custom::-ms-input-placeholder {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  padding: 25px;
}

.comment-form form .form-box .form-control-custom::placeholder {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  padding: 25px;
}

/* News sidebar */
.sidebar .widget {
  padding: 40px;
  background-color: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.sidebar .widget:last-child {
  margin-bottom: 0;
}

.sidebar .widget .widget-title {
  font-size: 17px;
  margin-bottom: 40px;
  line-height: 1;
  position: relative;
  font-weight: 600;
  line-height: 28px;
  z-index: 1;
}

.sidebar .widget .widget-title span {
  background-color: white;
  padding-right: 15px;
}

.sidebar .widget .widget-title::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #eee;
  content: "";
  z-index: -1;
}

.sidebar .widget.search-widget form {
  position: relative;
}

.sidebar .widget.search-widget form input {
  width: 100%;
  background-color: transparent;
  height: 55px;
  border: none;
  padding: 0 80px 0 30px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 0;
  border: 1px solid #eee;
  border-radius: 3pxpx;
}

.sidebar .widget.search-widget form input::-webkit-input-placeholder {
  color: #333;
}

.sidebar .widget.search-widget form input:-ms-input-placeholder {
  color: #333;
}

.sidebar .widget.search-widget form input::-ms-input-placeholder {
  color: #333;
}

.sidebar .widget.search-widget form input::placeholder {
  color: #333;
}

.sidebar .widget.search-widget form button {
  border: none;
  position: absolute;
  right: 7px;
  top: 6px;
  width: 55px;
  height: 42px;
  z-index: 1;
  color: #fff !important;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #fff;
  border-radius: 0;
  border-radius: 3px;
  padding: 0 !important;
  border: none;
  background: crimson;
}

.sidebar .widget.search-widget form button:hover {
  background-color: #081828;
  color: #fff;
}

.sidebar .widget.popular-feeds .single-popular-feed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px dashed #eee;
}

.sidebar .widget.popular-feeds .single-popular-feed:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-img {
  width: 80px;
  margin-right: 20px;
  border-radius: 50%;
  height: 80px;
  border-radius: 50% !important;
  overflow: hidden;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 1.5;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .post-title a {
  font-size: 14px;
  font-weight: 500;
}

.sidebar
  .widget.popular-feeds
  .single-popular-feed
  .feed-desc
  .post-title
  a:hover {
  color: crimson;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time {
  font-weight: 400;
  font-size: 12px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time > i {
  margin-right: 4px;
}

.sidebar .widget.categories-widget ul li {
  margin-bottom: 10px;
}

.sidebar .widget.categories-widget ul li:last-child {
  margin-bottom: 0;
}

.sidebar .widget.categories-widget ul li a {
  font-size: 14px;
  height: 35px;
  background-color: transparent;
  display: block;
  position: relative;
  line-height: 35px;
  color: #333;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-weight: 500;
  padding: 0;
}

.sidebar .widget.categories-widget ul li a span {
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: transparent;
  color: #333;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 3px;
  border: 1px solid #eee;
  font-size: 11px;
}

.sidebar .widget.categories-widget ul li a:hover {
  color: crimson;
}

.sidebar .widget.categories-widget ul li a:hover span {
  background: crimson;
  color: #fff;
  border-color: transparent;
}

.sidebar .widget.popular-tag-widget {
  padding-bottom: 30px;
}

.popular-tag-widget .tags > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px 20px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  background: crimson;
  margin-right: 7px;
  margin-bottom: 10px;
  color: #fff;
  background: transparent;
  color: #888;
  border: 1px solid #eee;
  border-radius: 4px;
}

.popular-tag-widget .tags > a:hover {
  background-color: crimson;
  color: #fff;
  border-color: transparent;
}

/*======================================
   Clients CSS
========================================*/
.client-logo-section {
  background: #f9faff;
  padding: 50px 0;
}

.client-logo-section .client-logo-wrapper .client-logo-carousel .client-logo {
  padding: 10px;
  text-align: center;
  margin: auto;
}

.client-logo-section
  .client-logo-wrapper
  .client-logo-carousel
  .client-logo
  img {
  max-width: 220px;
  width: 100%;
  opacity: 0.3;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.client-logo-section
  .client-logo-wrapper
  .client-logo-carousel
  .client-logo
  img:hover {
  opacity: 1;
}

/*======================================
    Apply Process CSS
========================================*/
.apply-process {
  background-color: crimson;
  padding: 80px 0;
}

.apply-process .process-item {
  padding-left: 92px;
  position: relative;
  padding-right: 25px;
}

.apply-process .process-item i {
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  height: 65px;
  width: 65px;
  border: 1px dashed #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 62px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.apply-process .process-item:hover i {
  background-color: #fff;
  color: crimson;
  border-color: transparent;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.apply-process .process-item h4 {
  font-size: 18px;
  color: #fff;
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.apply-process .process-item h4::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background-color: #fff;
}

.apply-process .process-item p {
  color: #fff;
  display: block;
}

/*======================================
    Job Category CSS
========================================*/
.job-category {
  background-color: #f6f9fc;
}

.job-category.style2 .cat-head {
  padding: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.job-category.style2 .single-cat {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  padding: 0;
  border: none;
  text-align: left;
  border-radius: 7px;
  overflow: hidden;
  background-color: #fff;
}

.job-category.style2 .single-cat:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.job-category.style2 .single-cat h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.job-category.style2 .single-cat .top-side {
  position: relative;
  overflow: hidden;
}

.job-category.style2 .single-cat .top-side img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 0;
}

.job-category.style2 .single-cat .top-side::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: crimson;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 2;
}

.job-category.style2 .single-cat .cat-head {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.job-category.style2 .single-cat .bottom-side {
  padding: 25px 30px;
  position: relative;
}

.job-category.style2 .single-cat .bottom-side .available-job {
  background-color: crimson;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  padding: 10px 15px;
  position: absolute;
  right: 30px;
  top: -19px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  z-index: 3;
}

.job-category.style2 .single-cat:hover .top-side:before {
  opacity: 0.5;
  visibility: visible;
}

.job-category .cat-head {
  padding: 35px 50px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
}

.job-category .single-cat {
  text-align: center;
  padding: 30px;
  border: 1px dashed #eee;
  margin: 15px 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: block;
}

.job-category .single-cat:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
}

.job-category .single-cat i {
  font-size: 26px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  background: crimson1c;
  color: crimson;
  border-radius: 4px;
}

.job-category .single-cat h3 {
  display: block;
  margin-top: 20px;
  font-size: 15px;
  color: #081828;
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.job-category .single-cat h3:hover {
  color: crimson;
}

.all-categories {
  border-bottom: 1px solid #eee;
  padding-bottom: 70px;
}

.all-categories .categories-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.all-categories .cat-title {
  font-size: 16px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  background: crimson;
  color: #fff;
}

.all-categories .cat-title span {
  font-weight: 400;
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
}

.all-categories ul {
  margin-bottom: 30px;
}

.all-categories ul li {
  display: block;
  margin-bottom: 7px;
}

.all-categories ul li:last-child {
  margin: 0;
}

.all-categories ul li a {
  color: #888;
  padding: 10px 15px;
  border: 1px solid #eee;
  display: block;
  border-radius: 4px;
}

.all-categories ul li a:hover {
  color: #fff;
  background-color: crimson;
  border-color: transparent;
}

/*======================================
    Find Job CSS
========================================*/
.find-job {
  background-color: #f6f9fc;
}

.find-job .section-title {
  margin-bottom: 50px;
}

.find-job.job-list {
  padding-top: 70px;
}

.find-job .single-job {
  margin-top: 30px;
  padding: 40px;
  padding-left: 120px;
  border: 1px solid #eee;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #fff;
}

.find-job .single-job:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
}

.find-job .single-job .job-image {
  position: absolute;
  left: 40px;
  top: 25px;
  height: 50px;
  width: 50px;
  line-height: 50px;
}

/* .find-job .single-job .job-content {
  position: relative;
} */

.find-job .single-job .job-content h4 {
  padding-right: 200px;
  position: relative;
  padding-bottom: 20px;
}

.find-job .single-job .job-content h4 a {
  font-size: 18px;
  font-weight: 500;
  color: #081828;
}

.find-job .single-job .job-content h4 a:hover {
  color: crimson;
}

.find-job .single-job .job-content p {
  display: inline-block;
  border-top: 1px solid #eee;
  padding-top: 18px;
  margin-left: -90px;
}

.find-job .single-job .job-content ul {
  margin-top: 20px;
  margin-left: -90px;
}

.find-job .single-job .job-content ul li {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 3px;
  position: relative;
  background: crimson14;
  color: crimson;
  padding: 5px 10px;
  border-radius: 3px;
}

.find-job .single-job .job-content ul li:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.find-job .single-job .job-content ul li i {
  font-size: 13px;
  display: inline-block;
  margin-right: 3px;
}

.find-job .single-job .job-content ul li a {
  color: #777;
}

.find-job .single-job .job-content ul li a:hover {
  color: crimson;
}

@media screen and (max-width:767px) {
  .find-job .single-job .job-content p {
    margin-left: 0px;
  }
  .find-job .single-job .job-content ul {
    margin-left: 0px;
  }
}

.find-job .single-job .job-button {
  position: absolute;
  right: 40px;
  top: 40px;
}

.find-job .single-job .job-button ul li {
  display: inline-block;
}

.find-job .single-job .job-button ul li a {
  padding: 5px 20px;
  border: 1px solid #eee;
  color: #081828;
  border-radius: 4px;
  font-size: 13px;
  text-transform: capitalize;
}

.find-job .single-job .job-button ul li a:hover {
  color: #fff;
  background-color: crimson;
  border-color: transparent;
}

.find-job .single-job .job-button ul li span {
  padding: 5px 20px;
  background-color: crimson;
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  text-transform: capitalize;
}

.find-job .search-job .search-nner {
  padding: 30px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 6px;
}

.find-job .search-job .search-nner input {
  height: 50px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 14px;
  color: #081828;
  padding: 0px 20px;
}

.find-job .search-job .search-nner .btn {
  width: 100%;
}

/*======================================
    Featured Job CSS
========================================*/
.featured-job {
  background-color: #fff;
}

.featured-job .section-title {
  margin-bottom: 50px;
}

.featured-job .single-job {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  margin-top: 30px;
  border-radius: 5px;
  position: relative;
  border-radius: 6px;
}

.featured-job .single-job:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.featured-job .single-job .feature {
  position: absolute;
  top: 16px;
  right: -36px;
  padding: 3px 35px;
  font-size: 12px;
  color: #fff;
  background-color: crimson;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
  -webkit-clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%, 20% 0%);
  clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%, 20% 0%);
}

.featured-job .single-job .shape::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 7px;
  right: 77px;
  top: -7px;
  background-color: #0221b3;
}

.featured-job .single-job .shape:after {
  position: absolute;
  content: "";
  right: -6px;
  top: 76px;
  width: 7px;
  height: 8px;
  background-color: #0221b3;
}

.featured-job .single-job .image {
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}

.featured-job .single-job .image img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.featured-job .single-job .content {
  background-color: #fff;
  padding: 20px 30px 30px 30px;
  border-radius: 0 0 6px 6px;
}

.featured-job .single-job .content h4 {
  display: block;
  margin-bottom: 18px;
  padding-bottom: 15px;
  position: relative;
}

.featured-job .single-job .content h4::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #eee;
}

.featured-job .single-job .content h4 a {
  font-size: 19px;
  font-weight: 500;
  color: #081828;
  text-transform: capitalize;
}

.featured-job .single-job .content h4 a:hover {
  color: crimson;
}

.featured-job .single-job .content ul li {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 3px;
  position: relative;
  background: crimson14;
  color: crimson;
  padding: 5px 10px;
  border-radius: 3px;
}

.featured-job .single-job .content ul li:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

.featured-job .single-job .content ul li i {
  font-size: 13px;
  display: inline-block;
  margin-right: 3px;
}

.featured-job .single-job .content p {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

.featured-job .single-job .content .button {
  display: block;
  margin-top: 20px;
}

.featured-job .single-job .content .button .btn {
  padding: 12px 34px;
  margin-right: 10px;
}

.featured-job .single-job .content .button .btn i {
  display: inline-block;
  margin-right: 5px;
}

.featured-job .single-job .content .button .btn:last-child {
  margin-right: 0;
}

.featured-job .single-job .content .button .btn.save {
  background-color: transparent;
  color: #081828;
  border: 1px solid #eee;
}

.featured-job .single-job .content .button .btn.save:hover {
  background-color: crimson;
  color: #fff;
  border-color: transparent;
}

/*======================================
    Call Action CSS
========================================*/
.call-action {
  background-image: url("../images/call-action/cat-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
}

.call-action.style2 {
  background-color: crimson;
  background-image: none;
  padding: 70px 0;
}

.call-action.style2 .text h2 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.call-action.style2 .button {
  position: relative;
}

.call-action.style2 .button::before {
  position: absolute;
  content: "";
  left: 0;
  top: 26px;
  height: 2px;
  width: 48%;
  border: 1px dashed #fff;
}

.call-action.style2 .button .btn {
  background-color: #fff;
  color: crimson;
  float: right;
}

.call-action.style2 .button .btn:hover {
  background-color: #081828;
  color: #fff;
}

.call-action .section-title {
  padding: 0;
  padding: 0px 50px;
  margin: 0;
}

.call-action .section-title span {
  color: crimson;
  background-color: #fff;
}

.call-action .section-title h2 {
  text-transform: none;
  color: #fff;
}

.call-action .section-title h2::before {
  background-color: #fff;
}

.call-action .section-title p {
  color: #fff;
}

.call-action .section-title .button {
  display: block;
  margin-top: 50px;
}

.call-action .section-title .button .btn {
  background-color: #fff;
  color: crimson;
}

.call-action .section-title .button .btn i {
  display: inline-block;
  margin-right: 5px;
  font-size: 15px;
}

.call-action .section-title .button .btn:hover {
  color: #fff;
  background-color: #081828;
}

.call-action.overlay::before {
  background-color: crimson;
  opacity: 0.8;
  z-index: -1;
}

/*======================================
    Job Details CSS
========================================*/
.job-details {
  background-color: #f6f9fc;
}

.job-details .job-details-inner {
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #eee;
  background-color: #fff;
}

.job-details .job-details-head {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ededed;
}

.job-details .job-details-head .content .meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  color: #7e8890;
  display: block;
  margin-top: 10px;
}

.job-details .job-details-head .salary-type .salary-range {
  font-weight: 500;
  line-height: 1;
  display: block;
  margin-bottom: 8px;
  background-color: #7e8890;
}

.job-details .job-details-head .badge {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 3px;
  position: relative;
  background: crimson14;
  color: crimson;
  padding: 8px 12px;
  border-radius: 3px;
  font-weight: 400;
  display: inline-block;
  margin-top: 5px;
}

.job-details .job-details-head li {
  display: inline-block;
  margin-right: 15px;
}

.job-details .job-details-head li i {
  color: crimson;
}

.job-details .job-details-head li:last-child {
  margin-right: 0;
}

.job-details .job-details-head li a {
  color: crimson;
  font-weight: 500;
}

.job-details .job-details-head li a:hover {
  text-decoration: underline;
}

.job-details .job-details-body h6 {
  font-size: 20px;
}

.job-details .job-details-body p {
  margin: 20px 0;
}

.job-details .job-details-body ul {
  margin-left: 10px;
}

.job-details .job-details-body ul li {
  display: block;
  margin-bottom: 8px;
  position: relative;
  padding-left: 15px;
}

.job-details .job-details-body ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  height: 6px;
  width: 6px;
  display: block;
  border-radius: 50%;
  background-color: crimson;
}

.job-details .job-details-body ul li:last-child {
  margin-bottom: 0;
}

.job-details .job-details-sidebar .sidebar-widget {
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #eee;
  background-color: #fff;
  margin-bottom: 30px;
}

.job-details .job-details-sidebar .sidebar-widget:last-child {
  margin-bottom: 0;
}

.job-details .job-details-sidebar .sidebar-widget .title {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1;
  position: relative;
  padding-left: 18px;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
}

.job-details .job-details-sidebar .sidebar-widget .title::before {
  position: absolute;
  left: 0;
  top: 1px;
  height: 100%;
  width: 3px;
  background-color: crimson;
  content: "";
}

.job-details .job-details-sidebar .sidebar-widget .list-unstyled li {
  display: block;
  margin-bottom: 8px;
}

.job-details .job-details-sidebar .sidebar-widget .list-unstyled li strong {
  color: #081828;
  font-weight: 600;
  display: inline-block;
  margin-right: 2px;
}

.job-details .job-details-sidebar .sidebar-widget .list-unstyled li:last-child {
  margin-bottom: 0;
}

/*======================================
    Job Post CSS
========================================*/
.job-post {
  background-color: #f6f9fc;
}

.job-post .job-information {
  padding: 40px;
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #fff;
}

.job-post .job-information .form-group {
  display: block;
  margin-bottom: 20px;
}

.job-post .job-information .form-group input {
  height: 50px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0px 20px;
  font-size: 14px;
}

.job-post .job-information .form-group textarea {
  height: 200px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  font-size: 14px;
}

.job-post .job-information .form-group .checkboxs {
  width: auto !important;
  display: inline-block !important;
}

.job-post .job-information .form-group .select {
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: 1px solid #ebebeb;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 30px; */
  font-weight: 400;
}

.job-post .job-information .form-group .select option {
  color: #081828;
  padding: 10px 10px;
  font-weight: 400;
}

.job-post .job-information .form-group label {
  font-size: 14px;
  color: #081828;
  display: block;
  margin-bottom: 5px;
}

.job-post .job-information .form-group.checkboxs {
  position: relative;
  padding-left: 25px;
  color: #081828;
}

.job-post .job-information .form-group.checkboxs #chb2 {
  position: absolute;
  top: -13px;
  left: 0;
}

.job-post .job-information .choose-img {
  margin-bottom: 30px;
}

.job-post .job-information .choose-img p {
  margin: 10px 0;
  display: block;
  color: #081828;
}

.job-post .job-information .choose-img label {
  display: block;
  margin-bottom: 10px;
  color: #081828;
}

.job-post .title {
  font-size: 22px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
}

/*======================================
    Resume CSS
========================================*/
.resume {
  background-color: #f6f9fc;
}

.resume .inner-content {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.resume .inner-content .name-head {
  text-align: left;
}

.resume .inner-content .name {
  font-size: 18px;
  color: #081828;
}

.resume .inner-content .name:hover {
  color: crimson;
}

.resume .inner-content .deg {
  font-size: 14px;
  color: #081828;
}

.resume .inner-content .social {
  margin-top: 20px;
}

.resume .inner-content .social li {
  display: inline-block;
  margin-right: 8px;
}

.resume .inner-content .social li a {
  font-size: 13px;
  color: #081828;
  height: 35px;
  width: 35px;
  text-align: center;
  display: block;
  line-height: 35px;
  background: #e8ecf1;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 3;
}

.resume .inner-content .social li a:hover {
  color: #fff;
  background-color: crimson;
}

.resume .inner-content .content-right .single-list {
  margin-bottom: 20px;
  width: 48%;
  display: inline-block;
  margin-top: 5px;
}

.resume .inner-content .content-right .single-list:last-child {
  margin: 0;
}

.resume .inner-content .content-right .single-list .title {
  font-size: 17px;
  font-weight: 500;
}

.resume .inner-content .content-right .single-list p {
  color: #081828;
  margin-top: 0px;
  display: block;
  color: #7e8890;
}

.resume .inner-content .content-right .single-list p a {
  background-color: #7e8890;
  display: inline-block;
  margin-top: 3px;
  font-weight: 400;
}

.resume .inner-content .content-right .single-list p a:hover {
  color: crimson;
}

.resume .inner-content .content-right .title-main {
  font-size: 20px;
  color: #081828;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.resume .inner-content .single-section {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #eee;
}

.resume .inner-content .single-section h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.resume .inner-content .single-section p {
  margin: 20px 0;
}

.resume .inner-content .single-section.skill ul li {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}

.resume .inner-content .single-section.skill ul li a {
  background: crimson14;
  color: #081828;
  padding: 8px 15px;
  border-radius: 3px;
  font-size: 13px;
}

.resume .inner-content .single-section.skill ul li a:hover {
  color: #fff;
  background-color: crimson;
}

.resume .inner-content .single-section.exprerience .single-exp .image img {
  border-radius: 5px;
  margin-right: 25px;
  position: relative;
  top: 5px;
}

.resume .inner-content .single-section.exprerience .single-exp h3 {
  margin-bottom: 5px;
}

.resume .inner-content .single-section.exprerience .single-exp h3 a {
  font-size: 18px;
  font-weight: 500;
  color: #081828;
}

.resume .inner-content .single-section.exprerience .single-exp h3 a:hover {
  color: crimson;
}

.resume .inner-content .single-section.exprerience .single-exp a {
  background-color: #7e8890;
}

.resume .inner-content .single-section.education .single-edu .image img {
  border-radius: 5px;
  margin-right: 25px;
  position: relative;
  top: 5px;
}

.resume .inner-content .single-section.education .single-edu h3 {
  margin-bottom: 5px;
}

.resume .inner-content .single-section.education .single-edu h3 a {
  font-size: 18px;
  font-weight: 500;
  color: #081828;
}

.resume .inner-content .single-section.education .single-edu h3 a:hover {
  color: crimson;
}

.resume .inner-content .single-section.education .single-edu a {
  background-color: #7e8890;
}

.dashbord-sidebar {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.dashbord-sidebar ul .heading {
  font-size: 20px;
  font-weight: 600;
  color: #081828;
  margin-bottom: 20px;
}

.dashbord-sidebar ul li {
  display: blocks;
  margin-bottom: 12px;
}

.dashbord-sidebar ul li:last-child {
  margin: 0;
}

.dashbord-sidebar ul li a {
  color: #081828;
  padding: 10px 15px;
  border: 1px solid #eee;
  display: block;
  border-radius: 5px;
  font-weight: 500;
}

.dashbord-sidebar ul li a:hover {
  background-color: crimson;
  color: #fff;
  border-color: transparent;
}

.dashbord-sidebar ul li a:hover i {
  background-color: #fff;
  color: crimson;
}

.dashbord-sidebar ul li a.active {
  background-color: crimson;
  color: #fff;
  border-color: transparent;
}

.dashbord-sidebar ul li a.active i {
  background-color: #fff;
  color: crimson;
}

.dashbord-sidebar ul li a i {
  font-size: 14px;
  color: crimson;
  display: inline-block;
  margin-right: 8px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  background: crimson0d;
  border-radius: 3px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.dashbord-sidebar ul li a .notifi {
  height: 25px;
  width: auto;
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  background: #f4f5fe;
  border: 1px solid #eee;
  border-radius: 3px;
  text-align: right;
  display: inline-block;
  text-align: center;
  color: crimson;
  position: relative;
  top: 2px;
  padding: 0px 8px;
  float: right !important;
}

.dashbord-sidebar ul li a:hover .notifi {
  background-color: #fff;
}

/*======================================
    Privacy Policy CSS
========================================*/
.privacy-policy {
  background-color: #f6f9fc;
}

.privacy-policy .policy-inner {
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #eee;
  background-color: #fff;
}

.privacy-policy .policy-details-body h6 {
  font-size: 20px;
}

.privacy-policy .policy-details-body p {
  margin: 20px 0;
  line-height: 26px;
}

.privacy-policy .policy-details-body ul {
  margin-left: 10px;
}

.privacy-policy .policy-details-body ul li {
  display: block;
  margin-bottom: 8px;
  position: relative;
  padding-left: 15px;
}

.privacy-policy .policy-details-body ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  height: 6px;
  width: 6px;
  display: block;
  border-radius: 50%;
  background-color: crimson;
}

.privacy-policy .policy-details-body ul li:last-child {
  margin-bottom: 0;
}

/*======================================
    Add Resume CSS
========================================*/
.add-resume {
  background-color: #f6f9fc;
}

.add-resume .add-resume-inner {
  padding: 40px;
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #fff;
}

.add-resume .add-resume-inner .single-section-title {
  font-size: 20px;
  font-weight: 700;
  color: #081828;
  display: block;
  margin-top: 30px;
  margin-bottom: 20px;
}

.add-resume .add-resume-inner .add-post-btn ul {
  position: relative;
  top: -8px;
}

.add-resume .add-resume-inner .add-post-btn ul li {
  display: inline-block;
  margin-right: 10px;
}

.add-resume .add-resume-inner .add-post-btn ul li:last-child {
  margin: 0;
}

.add-resume .add-resume-inner .add-post-btn ul li a {
  font-size: 13px;
  position: relative;
  background: crimson14;
  color: #081828;
  padding: 10px 15px;
  border-radius: 3px;
}

.add-resume .add-resume-inner .add-post-btn ul li a:hover {
  color: #fff;
  background-color: crimson;
}

.add-resume .add-resume-inner .add-post-btn ul li a i {
  display: inline-block;
  margin-right: 4px;
}

.add-resume .add-resume-inner .post-header {
  margin-bottom: 30px;
  overflow: hidden;
}

.add-resume .add-resume-inner .post-header h3 {
  font-size: 20px;
  font-weight: 700;
  color: #081828;
  float: left;
}

.add-resume .add-resume-inner .post-header p {
  float: right;
  position: relative;
  top: 2px;
}

.add-resume .add-resume-inner .post-header p a {
  color: crimson;
}

.add-resume .add-resume-inner .post-header p a:hover {
  text-decoration: underline;
}

.add-resume .add-resume-inner .form-ad .form-group {
  margin-bottom: 15px;
}

.add-resume .add-resume-inner .form-ad .form-group label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
  color: #081828;
}

.add-resume .add-resume-inner .form-ad .form-group input {
  height: 50px;
  width: 100%;
  padding: 0px 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 14px;
}

.add-resume .add-resume-inner .form-ad .form-group textarea {
  height: 200px;
  width: 100%;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 14px;
}

.add-resume .add-resume-inner .form-ad .form-group .action-buttons {
  position: relative;
}

.add-resume
  .add-resume-inner
  .form-ad
  .form-group
  .upload-button
  input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  cursor: pointer;
  padding: 0;
}

/*======================================
    Manage Resumes CSS
========================================*/
.manage-resumes {
  background-color: #f6f9fc;
}

.manage-resumes .inner-content {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.manage-resumes .inner-content .resume-item {
  position: relative;
  background-color: #fff;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-left: 190px;
  margin-bottom: 30px;
}

.manage-resumes .inner-content .resume-item:last-child {
  margin: 0;
  padding-bottom: 0;
  border: none;
}

.manage-resumes .inner-content .resume-item img {
  height: auto;
  width: 160px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.manage-resumes .inner-content .resume-item .right {
  position: relative;
  top: -10px;
}

.manage-resumes .inner-content .resume-item .right h3 a {
  font-size: 20px;
  font-weight: 600;
}

.manage-resumes .inner-content .resume-item .right h3 a:hover {
  color: crimson;
}

.manage-resumes .inner-content .resume-item .right .deg {
  display: block;
  margin-top: 5px;
}

.manage-resumes .inner-content .resume-item .right .experience {
  margin-top: 10px;
}

.manage-resumes .inner-content .resume-item .right .experience li {
  display: block;
  margin-bottom: 3px;
}

.manage-resumes .inner-content .resume-item .right .experience li span {
  color: crimson;
  display: inline-block;
  margin-left: 3px;
}

.manage-resumes .inner-content .resume-item .right .experience li i {
  display: inline-block;
  margin-right: 3px;
  color: crimson;
}

.manage-resumes .inner-content .resume-item .right .experience li:last-child {
  margin: 0;
}

.manage-resumes .inner-content .resume-item .right .skills {
  margin-top: 10px;
}

.manage-resumes .inner-content .resume-item .right .skills li {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 3px;
  position: relative;
  background: crimson14;
  color: crimson;
  padding: 5px 10px;
  border-radius: 3px;
}

.manage-resumes .inner-content .resume-item .update-date {
  display: block;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  overflow: hidden;
}

.manage-resumes .inner-content .resume-item .update-date .status {
  font-size: 13px;
  float: left;
  position: relative;
  top: 4px;
}

.manage-resumes .inner-content .resume-item .update-date .action-btn {
  float: right;
}

.manage-resumes .inner-content .resume-item .update-date .action-btn a {
  display: inline-block;
  margin-right: 5px;
  padding: 4px 10px;
  font-size: 12px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #7e8890;
}

.manage-resumes .inner-content .resume-item .update-date .action-btn a:hover {
  background-color: crimson;
  border-color: transparent;
  color: #fff;
}

.manage-resumes
  .inner-content
  .resume-item
  .update-date
  .action-btn
  a:last-child {
  margin: 0;
}

.manage-resumes .pagination {
  margin: 40px 0 0 0;
}

/*======================================
    Job Alerts CSS
========================================*/
.job-alerts {
  background-color: #f6f9fc;
}

.job-alerts .job-alerts-items {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.job-alerts .job-alerts-items .alerts-list {
  padding: 15px 0;
  padding-top: 0;
  border-bottom: 1px solid #f1f1f1;
  text-transform: capitalize;
}

.job-alerts .job-alerts-items .alerts-content {
  padding: 30px 0;
  border-bottom: 1px solid #f1f1f1;
}

.job-alerts .job-alerts-items .alerts-content:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.job-alerts .job-alerts-items .alerts-content .full-time {
  background: crimson14;
  color: crimson;
  padding: 5px 14px;
  border-radius: 3px;
  font-size: 13px;
}

.job-alerts .job-alerts-items .alerts-content .part-time {
  background: crimson14;
  color: crimson;
  padding: 5px 14px;
  border-radius: 3px;
  font-size: 13px;
}

.job-alerts .job-alerts-items .alerts-content h3 {
  font-size: 15px;
  font-weight: 500;
}

.job-alerts .job-alerts-items .alerts-content .location {
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

.job-alerts .job-alerts-items .alerts-content .location i {
  display: inline-block;
  margin-right: 3px;
  color: crimson;
}

/*======================================
    Manage Jobs CSS
========================================*/
.manage-jobs {
  background-color: #f6f9fc;
}

.manage-jobs .job-items {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.manage-jobs .job-items .manage-list {
  padding: 15px 0;
  padding-top: 0;
  border-bottom: 1px solid #f1f1f1;
  text-transform: capitalize;
}

.manage-jobs .job-items .manage-content {
  padding: 25px 0;
  border-bottom: 1px solid #f1f1f1;
}

.manage-jobs .job-items .manage-content:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.manage-jobs .job-items .manage-content .time {
  background: crimson14;
  color: crimson;
  padding: 5px 14px;
  border-radius: 3px;
  font-size: 13px;
}

.manage-jobs .job-items .manage-content .can-img img {
  width: 50px;
  margin: 5px 15px;
  border-radius: 50%;
}

.manage-jobs .job-items .manage-content p i {
  font-size: 14px;
}

.manage-jobs .job-items .manage-content h3 {
  font-size: 15px;
  font-weight: 500;
}

.manage-jobs .job-items .manage-content .location {
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

.manage-jobs .job-items .manage-content .location i {
  display: inline-block;
  margin-right: 3px;
  color: crimson;
}

/*======================================
    Manage Applications CSS
========================================*/
.manage-applications {
  background-color: #f6f9fc;
}

.manage-applications .job-items {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.manage-applications .job-items .manage-content {
  padding: 25px 0;
  border-bottom: 1px solid #f1f1f1;
}

.manage-applications .job-items .manage-content:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.manage-applications .job-items .manage-content .title-img .can-img {
  float: left;
  margin-right: 8px;
}

.manage-applications .job-items .manage-content .title-img h3 {
  font-weight: 600;
  position: relative;
  top: 8px;
}

.manage-applications .job-items .manage-content .title-img h3 span {
  font-size: 13px;
  font-weight: 400;
  display: block;
  margin-top: 5px;
}

.manage-applications .job-items .manage-content .time {
  background: crimson14;
  color: crimson;
  padding: 5px 14px;
  border-radius: 3px;
  font-size: 13px;
}

.manage-applications .job-items .manage-content .can-img img {
  width: 50px;
  margin: 5px 15px;
}

.manage-applications .job-items .manage-content p i {
  font-size: 14px;
}

.manage-applications .job-items .manage-content h3 {
  font-size: 15px;
  font-weight: 500;
}

.manage-applications .job-items .manage-content .location {
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

.manage-applications .job-items .manage-content .location i {
  display: inline-block;
  margin-right: 3px;
  color: crimson;
}

/*======================================
    Browse Resumes CSS
========================================*/
.browse-resumes {
  background-color: #f6f9fc;
}

.browse-resumes .inner-content {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.browse-resumes .inner-content .resume-item {
  position: relative;
  background-color: #fff;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-left: 190px;
  margin-bottom: 30px;
}

.browse-resumes .inner-content .resume-item:last-child {
  margin: 0;
  padding-bottom: 0;
  border: none;
}

.browse-resumes .inner-content .resume-item img {
  height: auto;
  width: 160px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.browse-resumes .inner-content .resume-item .right {
  position: relative;
  top: -10px;
}

.browse-resumes .inner-content .resume-item .right h3 a {
  font-size: 20px;
  font-weight: 600;
}

.browse-resumes .inner-content .resume-item .right h3 a:hover {
  color: crimson;
}

.browse-resumes .inner-content .resume-item .right .deg {
  display: block;
  margin-top: 5px;
}

.browse-resumes .inner-content .resume-item .right .experience {
  margin-top: 10px;
}

.browse-resumes .inner-content .resume-item .right .experience li {
  display: block;
  margin-bottom: 3px;
}

.browse-resumes .inner-content .resume-item .right .experience li span {
  color: crimson;
  display: inline-block;
  margin-left: 3px;
}

.browse-resumes .inner-content .resume-item .right .experience li i {
  display: inline-block;
  margin-right: 3px;
  color: crimson;
}

.browse-resumes .inner-content .resume-item .right .experience li:last-child {
  margin: 0;
}

.browse-resumes .inner-content .resume-item .right .skills {
  margin-top: 10px;
}

.browse-resumes .inner-content .resume-item .right .skills li {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 3px;
  position: relative;
  background: crimson14;
  color: crimson;
  padding: 5px 10px;
  border-radius: 3px;
}

.browse-resumes .inner-content .resume-item .right .description {
  margin-top: 20px;
}

.browse-resumes .inner-content .resume-item .right .year-exp {
  padding: 5px 15px;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  background-color: crimson;
  position: absolute;
  right: 0;
  top: 0;
}

.browse-resumes .inner-content .resume-item .right .year-exp:hover {
  background-color: #081828;
  color: #fff;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
}

/*======================================
    Bookmarked CSS
========================================*/
.bookmarked {
  background-color: #f6f9fc;
}

.bookmarked .job-items {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.bookmarked .job-items .manage-content {
  padding: 25px 0;
  border-bottom: 1px solid #f1f1f1;
}

.bookmarked .job-items .manage-content:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.bookmarked .job-items .manage-content .title-img .can-img {
  float: left;
  margin-right: 8px;
}

.bookmarked .job-items .manage-content .title-img h3 {
  font-weight: 600;
  position: relative;
  top: 8px;
}

.bookmarked .job-items .manage-content .title-img h3 span {
  font-size: 13px;
  font-weight: 400;
  display: block;
  margin-top: 5px;
}

.bookmarked .job-items .manage-content .time {
  background: crimson14;
  color: crimson;
  padding: 5px 14px;
  border-radius: 3px;
  font-size: 13px;
}

.bookmarked .job-items .manage-content .can-img img {
  width: 50px;
  margin: 5px 15px;
}

.bookmarked .job-items .manage-content .location {
  font-size: 13px;
  position: relative;
  padding-left: 20px;
}

.bookmarked .job-items .manage-content .location i {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
}

.bookmarked .job-items .manage-content p i {
  font-size: 14px;
}

.bookmarked .job-items .manage-content h3 {
  font-size: 15px;
  font-weight: 500;
}

.bookmarked .job-items .manage-content .button .btn {
  padding: 8px 12px;
  font-size: 13px;
}

/*======================================
    Notifications CSS
========================================*/
.notifications {
  background-color: #f6f9fc;
}

.notifications .job-items {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.notifications .job-items .manage-content {
  padding: 25px 0;
  border-bottom: 1px solid #f1f1f1;
}

.notifications .job-items .manage-content:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.notifications .job-items .manage-content .can-img img {
  width: 60px;
  margin: 5px 15px;
}

.notifications .job-items .manage-content .time p {
  background: crimson14;
  color: crimson;
  padding: 8px 14px;
  border-radius: 3px;
  font-size: 13px;
  display: inline-block;
  float: right;
}

.notifications .job-items .manage-content .time p i {
  display: inline-block;
  margin-right: 3px;
}

/*======================================
    Change Password CSS
========================================*/
.change-password {
  background-color: #f6f9fc;
}

.change-password .password-content {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

.change-password .password-content h3 {
  font-size: 22px;
  font-weight: 700;
}

.change-password .password-content p {
  display: block;
  margin-top: 5px;
}

.change-password .password-content form {
  margin-top: 30px;
}

.change-password .password-content form label {
  color: #081828;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.change-password .password-content form input {
  height: 50px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0px 20px;
  font-size: 14px;
}

.change-password .password-content .form-group {
  margin-bottom: 15px;
}

.change-password .password-content .button .btn {
  padding: 12px 25px;
}

/*======================================
    Responsive CSS
========================================*/
/* Tablet Screen */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section {
    padding: 60px 0px;
  }
  .section-title {
    margin-bottom: 50px;
    padding: 0px 90px;
  }
  .section-title span {
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 8px;
    font-size: 13px;
  }
  .section-title h2 {
    font-size: 28px;
    margin-top: 5px;
    line-height: 38px;
  }
  .section-title.align-left {
    padding: 0;
    padding-right: 200px;
  }
  .section-title p {
    font-size: 13px;
  }
  .breadcrumbs {
    padding-top: 105px;
    padding-bottom: 50px;
  }
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 30px;
    margin-bottom: 5px;
    color: #fff;
  }
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
  #scrollUp {
    bottom: 55px;
  }
  .navbar-brand img {
    width: 130px;
  }
  .header .button {
    margin: 0;
  }
  .header.index2 .sticky .navbar .navbar-toggler .toggler-icon {
    background: #081828 !important;
  }
  .header .navbar-nav li .sub-menu {
    background: #fff;
    width: 200px;
    padding: 10px;
    z-index: 9999999;
    left: 95px;
    opacity: 1;
    visibility: visible;
    position: relative;
    left: 13px;
    top: 0;
    -webkit-transform: scaleY(1) !important;
    transform: scaleY(1) !important;
    border: 1px dashed #eee;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header .navbar-nav li .sub-menu li a {
    padding: 5px 10px;
    font-size: 13px;
    padding: 8px 12px;
    font-size: 12px;
  }
  .header .navbar-nav li a:hover .sub-menu {
    top: 42px;
  }
  .header .navbar-nav li a {
    position: relative;
    margin-bottom: 6px;
  }
  .navbar-collapse {
    overflow: scroll;
    /* height: 400px; */
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .navbar-nav .nav-item:last-child {
    padding-bottom: 20px;
  }
  .right-button .btn {
    font-size: 13px;
  }
  .breadcrumbs .bread-list {
    margin-top: 8px;
  }
  .hero-area .hero-inner {
    height: 550px;
  }
  .hero-area .hero-inner::before {
    display: none;
  }
  .hero-area .hero-text {
    float: none;
    margin-top: 140px;
  }
  .hero-area .hero-text h1 {
    font-size: 30px;
    line-height: 38px;
  }
  .hero-area .hero-text .button {
    margin-top: 25px;
  }
  .hero-area .hero-video-head {
    display: none;
  }
  .hero-area.style2 .home-slider {
    position: relative;
    height: 450px;
  }
  .hero-area.style2 .hero-inner .hero-text {
    margin-top: 150px;
  }
  .hero-area.style2 .tns-controls button {
    width: 30px;
    height: 50px;
    z-index: 2;
    color: #fff;
    font-size: 15px;
    background: crimson;
    border: none;
    padding: 2px 5px;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: absolute;
    z-index: 99;
    top: 50%;
    margin-top: -25px;
  }
  .hero-area.style2 .tns-controls button:hover {
    background-color: #081828;
    color: #fff;
  }
  .hero-area.style2 .tns-controls button:first-child {
    border-radius: 0 5px 5px 0 !important;
    left: 0;
  }
  .hero-area.style2 .tns-controls button:last-child {
    border-radius: 5px 0 0 5px !important;
    right: 0;
  }
  .hero-area.style2 .hero-image {
    display: none !important;
  }
  .hero-area.style3 .hero-inner {
    height: auto !important;
    padding-bottom: 50px;
  }
  .hero-area.style3 .hero-inner .hero-text {
    margin-top: 120px;
    text-align: center;
    padding: 0 !important;
  }
  .hero-area.style3 .trending-keywords {
    text-align: center;
  }
  .hero-area.style3 .trending-keywords .keywords {
    text-align: center;
    display: inline-block;
  }
  .hero-area.style3 .home-search {
    margin-top: 40px;
  }
  .hero-area.style3 .home-search .button {
    margin-top: 20px;
  }
  .hero-area.style3 .home-search .button .btn {
    width: 100%;
  }
  .hero-area.style4 .hero-inner .hero-text {
    margin-top: 120px;
    text-align: center;
    padding: 0 !important;
  }
  .apply-process .process-item {
    padding: 0 !important;
    padding-top: 0px;
    text-align: center;
    padding-top: 80px !important;
  }
  .apply-process .process-item i {
    left: 50% !important;
    margin-left: -30px;
    height: 60px !important;
    width: 60px !important;
    font-size: 25px !important;
    line-height: 60px !important;
  }
  .apply-process .process-item h4:before {
    left: 50% !important;
    margin-left: -25px;
  }
  .call-action.style2 .text h2 {
    font-size: 24px !important;
  }
  .call-action.style2 .button::before {
    display: none;
  }
  .about-us .content-left {
    position: relative;
    text-align: center;
  }
  .about-us .content-left .md-left {
    margin-left: 0 !important;
  }
  .about-us .content-left .media-body {
    text-align: left;
  }
  .about-us .content-right {
    margin-top: 50px;
    padding: 0;
  }
  .about-us .content-right h2 {
    font-size: 27px;
    margin-bottom: 40px;
    line-height: 38px;
  }
  .featured-job .single-job .content .button .btn {
    padding: 12px 28px !important;
  }
  .testimonials .patern1 {
    display: none;
  }
  .testimonials .testimonial-right {
    display: none;
  }
  .all-categories .categories-title {
    font-size: 25px !important;
  }
  .find-job.job-list {
    padding-top: 30px !important;
  }
  .pricing-table .section-title {
    margin-bottom: 20px;
  }
  .pricing-table .single-table {
    margin-top: 30px;
  }
  .faq-area {
    padding-bottom: 30px !important;
  }
  .faq-area .heading {
    font-size: 17px;
    line-height: 28px;
  }
  .brand-area .section-title.align-left {
    padding-right: 200px;
    margin-bottom: 40px !important;
  }
  .newsletter-area .mini-call-action {
    height: auto;
  }
  .latest-news-area .section-title {
    margin-bottom: 20px;
  }
  .latest-news-area .single-news {
    margin-top: 30px;
  }
  .post-details .post-meta li {
    margin-bottom: 8px;
  }
  .latest-news-area.blog-list {
    padding-top: 30px;
  }
  .blog-list .single-news {
    margin-bottom: 0 !important;
  }
  .post-details p {
    margin: 25px 0;
  }
  .sidebar {
    margin-top: 30px;
  }
  .sidebar .widget.search-widget form input {
    padding: 0 80px 0 20px;
  }
  .sidebar .widget.social-widget ul li {
    margin-bottom: 10px;
  }
  .contact-area .contact-address-wrapper {
    padding-right: 0;
  }
  .contact-area .inner-section-title h2 {
    font-weight: 700;
    font-size: 28px;
  }
  .sidebar.service-sidebar .service-category > li > a {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    padding: 10px 15px;
  }
  .sidebar.service-sidebar .service-category {
    padding: 30px;
  }
  .sidebar.service-sidebar .service-docs {
    padding: 30px;
  }
  .sidebar.service-sidebar .service-quote {
    padding: 30px;
  }
  .adv-banner {
    padding: 30px;
  }
  .post-details .post-title {
    line-height: 30px;
  }
  .post-details .post-title a {
    font-size: 24px;
    font-weight: 600;
  }
  .blog-middle-image {
    margin-bottom: 20px;
  }
  .blog-single .sidebar {
    margin-top: 30px;
  }
  .map-section {
    background-color: #fff;
    margin-top: 50px;
    padding-bottom: 60px !important;
    height: auto;
  }
  .map-section iframe {
    height: 300px !important;
  }
  .newsletter-area .mini-call-action {
    margin-top: 30px;
  }
  .error-page .error-image img {
    width: 70%;
  }
  .error-page .error-text {
    margin-top: 50px;
    padding-left: 0 !important;
    text-align: center !important;
    padding: 0px 100px !important;
  }
  .error-area .error-content h1 {
    font-size: 90px !important;
  }
  .maill-success .success-content h1 {
    font-size: 30px !important;
  }
  .maill-success .success-content h2 {
    font-size: 20px !important;
  }
  .contact-us .form-main {
    padding: 50px 50px 50px 50px !important;
  }
  .contact-us .single-head {
    padding-top: 0 !important;
  }
  .dashbord-sidebar {
    margin-bottom: 30px;
  }
  .footer .footer-top {
    text-align: center;
  }
  .footer .footer-top .download-button {
    text-align: center !important;
    margin-top: 20px;
  }
  .footer .footer-middle {
    padding-bottom: 60px;
    padding-top: 30px;
  }
  .footer .f-about p {
    padding-right: 80px;
  }
  .footer .single-footer {
    margin-top: 30px;
  }
}

/* Mobile Screen */
@media only screen and (max-width: 767px) {
  .section {
    padding: 50px 0px;
  }
  .section-title {
    margin-bottom: 40px;
    padding: 0px 20px;
  }
  .section-title span {
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 8px;
    font-size: 12px;
  }
  .section-title h2 {
    font-size: 25px;
    margin-top: 3px;
    line-height: 35px;
  }
  .section-title.align-left {
    padding: 0;
    padding-right: 0;
  }
  .section-title p {
    font-size: 13px;
  }
  .breadcrumbs {
    padding-top: 105px;
    padding-bottom: 50px;
  }
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 5px;
    color: #fff;
  }
  .breadcrumbs .breadcrumb-nav {
    margin-top: 30px;
  }
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
  #scrollUp {
    bottom: 55px;
  }
  .navbar-brand img {
    width: 130px;
  }
  .header.index2 .navbar .navbar-nav .nav-item a {
    color: #081828;
  }
  .header.index2 .navbar .navbar-nav .nav-item a::before {
    background-color: crimson;
  }
  .header .button {
    margin: 0;
  }
  .header .button .btn {
    padding: 0 !important;
    width: auto !important;
    height: 30px;
    line-height: 30px;
    padding: 0px 10px !important;
    font-size: 13px;
  }
  .header .button .login {
    padding: 0 !important;
    width: auto !important;
    height: 30px;
    line-height: 30px;
    padding: 0px 10px !important;
    font-size: 13px;
    margin-right: 10px;
  }
  .header .button .login i {
    display: inline-block;
    margin-right: 6px;
    font-size: 13px;
    line-height: 30px;
  }
  .header .navbar-nav li .sub-menu {
    background: #fff;
    width: 200px;
    padding: 10px;
    z-index: 9999999;
    left: 95px;
    opacity: 1;
    visibility: visible;
    position: relative;
    left: 13px;
    top: 0;
    -webkit-transform: scaleY(1) !important;
    transform: scaleY(1) !important;
    border: 1px dashed #eee;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header .navbar-nav li .sub-menu li a {
    padding: 5px 10px;
    font-size: 13px;
    padding: 8px 12px;
    font-size: 12px;
  }
  .header .navbar-nav li a:hover .sub-menu {
    top: 42px;
  }
  .header .navbar-nav li a {
    position: relative;
    margin-bottom: 6px;
  }
  .navbar-collapse {
    overflow: scroll;
    /* height: 400px; */
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .navbar-nav .nav-item:last-child {
    padding-bottom: 20px;
  }
  .navbar-toggler {
    padding: 0;
    background-color: crimson;
    padding: 5px 8px !important;
  }
  .navbar-toggler .toggler-icon {
    background-color: #fff !important;
    width: 20px !important;
    margin: 3px 0 !important;
  }
  .navbar-toggler.active .toggler-icon:nth-of-type(1) {
    top: 5px !important;
  }
  .navbar-toggler.active .toggler-icon:nth-of-type(3) {
    top: -5px !important;
  }
  .right-button .btn {
    font-size: 13px;
  }
  .breadcrumbs .bread-list {
    margin-top: 8px;
  }
  .hero-area .job-search-wrap-two {
    margin-top: 30px !important;
  }
  .hero-area .hero-inner {
    height: auto;
    overflow: visible;
    padding-bottom: 50px;
  }
  .hero-area .hero-text {
    float: none;
    margin-top: 100px;
  }
  .hero-area .hero-text h1 {
    font-size: 23px;
    line-height: 30px;
  }
  .hero-area .hero-text .button {
    margin-top: 20px;
  }
  .hero-area .hero-text .button .btn {
    margin: 0 !important;
    width: 60%;
    margin-bottom: 10px !important;
  }
  .hero-area .hero-text .button .btn:last-child {
    margin: 0 !important;
  }
  .hero-area .hero-video-head {
    display: none;
  }
  .hero-area.style2 .home-slider {
    position: relative;
    height: auto !important;
  }
  .hero-area.style2 .hero-inner .hero-text {
    margin-top: 120px;
    padding: 0 !important;
  }
  .hero-area.style2 .hero-inner .hero-text .btn {
    margin: 0 !important;
    width: 100%;
    margin-bottom: 10px !important;
  }
  .hero-area.style2 .hero-inner .hero-text .btn:last-child {
    margin: 0 !important;
  }
  .hero-area.style2 .tns-controls button {
    width: 30px;
    font-size: 15px;
  }
  .hero-area.style2 .tns-controls button:hover {
    background-color: #081828;
    color: #fff;
  }
  .hero-area.style2 .tns-controls button:first-child {
    border-radius: 0 5px 5px 0 !important;
    left: 0;
  }
  .hero-area.style2 .tns-controls button:last-child {
    border-radius: 5px 0 0 5px !important;
    right: 0;
  }
  .hero-area.style2 .hero-image {
    display: none !important;
  }
  .hero-area.style3 .hero-inner {
    height: auto !important;
    padding-bottom: 50px;
  }
  .hero-area.style3 .hero-inner .hero-text {
    margin-top: 120px;
    text-align: center;
    padding: 0 !important;
  }
  .hero-area.style3 .trending-keywords {
    text-align: center;
  }
  .hero-area.style3 .trending-keywords .keywords {
    text-align: center;
    display: inline-block;
  }
  .hero-area.style3 .home-search {
    margin-top: 40px;
  }
  .hero-area.style4 .hero-inner .hero-text {
    margin-top: 120px;
    text-align: center;
    padding: 0 !important;
  }
  .apply-process {
    padding-bottom: 45px !important;
  }
  .apply-process.style4 {
    padding-top: 120px !important;
  }
  .apply-process .process-item {
    margin-bottom: 35px;
    padding-left: 80px !important;
  }
  .apply-process .process-item i {
    height: 50px !important;
    width: 50px !important;
    font-size: 20px !important;
    line-height: 50px !important;
    top: 5px !important;
  }
  .apply-process .process-item h4 {
    font-size: 16px !important;
    margin-bottom: 14px !important;
    padding-bottom: 16px !important;
  }
  .about-us .content-left {
    padding: 0;
  }
  .about-us .content-left::before {
    display: none;
  }
  .about-us .content-left .single-img {
    border-radius: 5px;
  }
  .about-us .content-left .single-img.mt-50 {
    margin-top: 30px !important;
  }
  .about-us .content-left .single-img.minus-margin {
    position: relative;
    top: 0px;
  }
  .about-us .content-left .media-body {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .about-us .content-left .media-body h6 {
    font-size: 20px;
  }
  .about-us .content-right {
    margin-top: 50px;
    padding: 0;
  }
  .about-us .content-right h2 {
    font-size: 27px;
    margin-bottom: 40px;
    line-height: 38px;
  }
  .call-action .section-title {
    padding: 0 !important;
  }
  .find-job.job-list {
    padding-top: 20px !important;
  }
  .find-job .single-job {
    padding: 0 !important;
    padding: 40px !important;
  }
  .find-job .single-job .job-image {
    margin: 0;
    position: relative !important;
    margin-bottom: 20px;
    left: 0 !important;
    top: 0 !important;
    display: inline-block;
  }
  .find-job .single-job .job-content h4 {
    padding: 0 !important;
    padding-bottom: 15px !important;
  }
  .find-job .single-job .job-button {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    margin-top: 20px;
  }
  .featured-job .single-job .content .button .btn {
    width: 100%;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .featured-job .single-job .content .button .btn:last-child {
    margin: 0 !important;
  }
  .all-categories {
    padding-bottom: 20px !important;
  }
  .all-categories .categories-title {
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }
  .find-job .search-job .search-nner input {
    margin-bottom: 15px !important;
  }
  .browse-resumes .inner-content .resume-item {
    padding-left: 0 !important;
  }
  .browse-resumes .inner-content .resume-item img {
    position: relative !important;
    margin-bottom: 20px;
  }
  .browse-resumes .inner-content .resume-item .right .year-exp {
    padding: 7px 15px !important;
    position: relative !important;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .testimonials .patern1 {
    display: none;
  }
  .testimonials .testimonial-right {
    display: none;
  }
  .testimonials .tns-controls {
    position: absolute;
    right: 50%;
    bottom: -65px;
    z-index: 9;
    text-align: center;
    display: inline-block;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  .testimonials button {
    width: 40px !important;
    height: 40px !important;
    font-size: 13px !important;
    line-height: 40px !important;
  }
  .pricing-table .section-title {
    margin-bottom: 10px;
  }
  .pricing-table .single-table {
    margin-top: 30px;
  }
  .faq-area {
    padding-bottom: 20px !important;
  }
  .faq-area .single-faq {
    margin-bottom: 45px;
  }
  .faq-area .heading {
    font-size: 15px;
    line-height: 25px;
  }
  .brand-area .section-title.align-left {
    padding-right: 0;
    margin-bottom: 40px !important;
  }
  .client-logo-section {
    padding: 0 !important;
  }
  .client-logo-section
    .client-logo-wrapper
    .client-logo-carousel
    .client-logo
    img {
    max-width: 170px;
  }
  .latest-news-area .section-title {
    margin-bottom: 10px;
  }
  .latest-news-area .single-news {
    margin-top: 30px;
  }
  .latest-news-area .single-news .content-body .title {
    line-height: 24px !important;
  }
  .latest-news-area .single-news .content-body .title a {
    font-size: 17px !important;
  }
  .post-details .post-meta li {
    margin-bottom: 8px;
  }
  .post-details p {
    margin: 20px 0;
  }
  .post-image .blog-inner-big-img {
    margin-bottom: 1.5rem !important;
  }
  .post-details .list {
    margin-left: 0 !important;
  }
  .post-details .list li i {
    top: 6px !important;
  }
  .post-comments {
    padding: 30px;
  }
  .post-comments .comments-list {
    padding: 0 !important;
  }
  .post-comments .comments-list li {
    padding: 0 !important;
  }
  .post-comments .comments-list li .comment-img {
    position: relative !important;
  }
  .comment-form {
    padding: 30px;
  }
  .comment-form form .form-box .form-control-custom {
    height: 50px;
    margin-bottom: 15px;
  }
  .post-comments .comments-list li.children {
    margin-left: 0 !important;
  }
  .post-comments .comments-list li {
    padding-top: 30px !important;
  }
  .post-comments .comments-list li .comment-desc {
    margin-top: -15px;
  }
  .post-comments .comments-list li .comment-img img {
    max-width: 70px;
    max-height: 70px;
    display: inline-block !important;
    margin-bottom: -15px !important;
  }
  .post-comments .comments-list li .comment-desc .desc-top .reply-link {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    padding: 8px 25px;
    position: relative;
    margin-top: 15px;
  }
  .post-comments .comments-list li {
    padding-left: 100px;
  }
  .latest-news-area.blog-list {
    padding-top: 20px;
  }
  .blog-list .single-news {
    margin-bottom: 0 !important;
  }
  .sidebar {
    margin-top: 30px;
  }
  .sidebar .widget.search-widget form input {
    padding: 0 80px 0 20px;
  }
  .sidebar .widget.social-widget ul li {
    margin-bottom: 10px;
  }
  .contact-area .contact-address-wrapper {
    padding-right: 0;
  }
  .contact-area .inner-section-title h2 {
    font-weight: 700;
    font-size: 28px;
  }
  .sidebar.service-sidebar .service-category > li > a {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    padding: 10px 15px;
  }
  .sidebar.service-sidebar .service-category {
    padding: 30px;
  }
  .sidebar.service-sidebar .service-docs {
    padding: 30px;
  }
  .sidebar.service-sidebar .service-quote {
    padding: 30px;
  }
  .adv-banner {
    padding: 30px;
  }
  .post-details .post-title {
    line-height: 30px;
  }
  .post-details .post-title a {
    font-size: 24px;
    font-weight: 600;
  }
  .blog-middle-image {
    margin-bottom: 20px;
  }
  .blog-single .sidebar {
    margin-top: 30px;
  }
  .map-section {
    background-color: #fff;
    margin-top: 50px;
    padding-bottom: 50px !important;
    height: auto;
  }
  .map-section iframe {
    height: 300px !important;
  }
  .brand-list {
    border: none;
  }
  .brand-list:before {
    display: none;
  }
  .brand-list li {
    border: 1px solid #eee;
  }
  .brand-list li a {
    border: none;
  }
  .faq-area .single-faq {
    margin-bottom: 30px !important;
  }
  .error-area .error-content h1 {
    font-size: 60px !important;
  }
  .error-area .error-content h2 {
    font-size: 18px !important;
  }
  .maill-success .success-content h1 {
    font-size: 28px !important;
  }
  .maill-success .success-content h2 {
    font-size: 18px !important;
  }
  .contact-us .single-head {
    padding-top: 0 !important;
  }
  .add-resume .add-resume-inner .post-header p {
    float: left !important;
  }
  .add-resume .add-post-btn {
    float: none !important;
    margin-top: 10px;
  }
  .add-resume .button {
    margin-bottom: 15px;
    display: block;
  }
  .add-resume .add-resume-inner .add-post-btn ul li {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .add-resume .add-resume-inner .add-post-btn ul li:last-child {
    margin: 0 !important;
  }
  .job-alerts .job-alerts-items .alerts-list {
    text-align: center;
  }
  .job-alerts .job-alerts-items .alerts-content {
    text-align: center;
  }
  .job-alerts .job-alerts-items .alerts-content h3 {
    margin-bottom: 10px;
  }
  .pagination-md-center {
    text-align: center !important;
  }
  .job-alerts .job-alerts-items .alerts-content .full-time {
    margin: 5px 0;
  }
  .job-details .job-details-inner {
    padding: 30px !important;
  }
  .job-details .content {
    padding: 0 !important;
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }
  .job-details .company-logo {
    padding-left: 0 !important;
  }
  .job-details .title {
    display: block;
    margin-top: 10px;
  }
  .job-details .job-details-head .salary-type {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .job-details .job-details-sidebar .sidebar-widget:first-child {
    margin-top: 30px !important;
  }
  .modal .login-modal-main {
    padding: 25px !important;
  }
  .modal .login-modal-main .heading h3 {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .modal .login-modal-main .social-login ul li a {
    padding: 10px 10px !important;
    padding-left: 10px !important;
    font-size: 13px !important;
    padding-left: 32px !important;
  }
  .modal .login-modal-main .social-login ul li a i {
    position: absolute;
    left: 15px !important;
    top: 14px !important;
    font-size: 13px !important;
  }
  .dashbord-sidebar {
    margin-bottom: 30px;
  }
  .pagination {
    margin-top: 30px;
  }
  .pagination .pagination-list li {
    margin-top: 10px;
  }
  .bookmarked .job-items .manage-content {
    text-align: center;
  }
  .bookmarked .job-items .manage-content .can-img {
    float: none !important;
    text-align: center;
  }
  .bookmarked .job-items .manage-content .time {
    margin-top: 20px;
    padding: 7px 14px !important;
    display: inline-block !important;
    text-align: center;
    margin-bottom: 10px;
  }
  .bookmarked .job-items .manage-content .location {
    text-align: center;
    margin-bottom: 10px;
    padding: 0 !important;
  }
  .bookmarked .job-items .manage-content .location i {
    position: relative !important;
    top: 0 !important;
  }
  .bookmarked .job-items .manage-content .button .btn {
    display: inline-block !important;
    padding: 7px 14px;
  }
  .manage-applications .job-items .manage-content {
    text-align: center;
  }
  .manage-applications .job-items .manage-content .title-img .can-img {
    float: none !important;
  }
  .manage-applications .job-items .manage-content .time {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .manage-jobs .job-items .manage-list {
    text-align: center;
  }
  .manage-jobs .job-items .manage-content {
    text-align: center;
  }
  .manage-jobs .job-items .manage-content .time {
    display: inline-block;
    margin: 15px 0 10px 0;
  }
  .manage-jobs .job-items .manage-content .can-img img {
    margin-bottom: 10px !important;
  }
  .manage-resumes .inner-content .resume-item {
    padding: 0 !important;
  }
  .manage-resumes .inner-content .resume-item img {
    position: relative !important;
    margin-bottom: 20px;
  }
  .manage-resumes .inner-content .resume-item .status {
    display: block;
    margin-bottom: 10px;
  }
  .manage-resumes .inner-content .resume-item .action-btn a {
    margin-bottom: 10px;
  }
  .manage-resumes .inner-content .resume-item .action-btn a:last-child {
    margin-bottom: 10px !important;
  }
  .notifications .job-items .manage-content {
    text-align: center;
  }
  .notifications .job-items .manage-content .can-img img {
    float: none;
    margin-bottom: 15px !important;
  }
  .notifications .job-items .manage-content .time p {
    float: none !important;
    margin-top: 15px !important;
    text-align: center;
  }
  .resume .content-right {
    margin-top: 20px;
  }
  .resume .content-right .title-main {
    margin-bottom: 20px !important;
    padding-bottom: 15px !important;
  }
  .resume .inner-content .social li {
    margin-bottom: 10px;
  }
  .resume .inner-content .single-section.exprerience .single-exp .image img {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .call-action.style2 {
    text-align: center;
  }
  .call-action.style2 h2 {
    font-size: 28px !important;
  }
  .call-action.style2 .button {
    position: relative;
    text-align: center;
    margin-top: 20px;
  }
  .call-action.style2 .button:before {
    display: none !important;
  }
  .call-action.style2 .button .btn {
    float: none !important;
    text-align: center;
  }
  .footer .footer-top {
    text-align: center;
  }
  .footer .footer-top .download-text h3 {
    font-size: 22px !important;
  }
  .footer .footer-top .download-button {
    text-align: center !important;
    margin-top: 20px;
  }
  .footer .footer-top .download-button .button .btn {
    width: 70%;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .footer .footer-top .download-button .button .btn:last-child {
    margin: 0 !important;
  }
  .footer .footer-middle {
    padding-bottom: 50px !important;
    padding-top: 20px !important;
  }
  .footer .f-about p {
    padding-right: 80px;
    line-height: 24px !important;
  }
  .footer .f-link ul li {
    margin-bottom: 10px !important;
  }
  .footer .single-footer h3 {
    margin-bottom: 22px !important;
  }
  .footer .single-footer {
    margin-top: 30px;
  }
  .footer .footer-bottom {
    text-align: center;
  }
  .footer .footer-bottom .left {
    margin-bottom: 15px;
  }
  .footer .footer-bottom .left p {
    text-align: center;
  }
  .footer .footer-bottom .right ul {
    text-align: center !important;
  }
}

/*======================================
	Contact CSS
========================================*/
.contact-us {
  position: relative;
  background-color: #f6f9fc;
  padding-bottom: 0;
}

.contact-us .contact-head {
  background: #fff;
  border-radius: 7px;
  overflow: hidden;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  position: relative;
  z-index: 5;
}

.contact-us .title {
  margin-bottom: 30px;
}

.contact-us .contant-inner-title {
  margin-bottom: 45px;
}

.contact-us .contant-inner-title h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #081828;
  text-transform: capitalize;
}

.contact-us .contant-inner-title p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  margin-top: 10px;
  display: block;
}

.contact-us .single-head {
  padding: 50px;
  height: 100%;
  background: #fff;
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-left: 1px solid #eee;
}

.contact-us .single-info {
  text-align: left;
  margin-bottom: 16px;
  padding-left: 40px;
  position: relative;
}

.contact-us .single-info i {
  color: crimson;
  font-size: 18px;
  display: inline-block;
  margin-right: 20px;
  position: absolute;
  left: 0;
  top: 2px;
}

.contact-us .single-info ul {
  display: inline-block;
}

.contact-us .single-info ul li:last-child {
  margin-bottom: 0;
}

.contact-us .single-info ul li {
  font-weight: 500;
  display: inline-block;
  font-size: 13px;
}

.contact-us .single-info ul li a {
  font-weight: 500;
  background-color: #7e8890;
  display: inline-block;
  font-size: 13px;
}

.contact-us .single-info ul li a:hover {
  color: crimson;
}

.contact-us .contact-social {
  display: block;
  margin-top: 65px;
}

.contact-us .contact-social h5 {
  color: #081828;
  font-weight: 600;
  font-size: 18px;
}

.contact-us .contact-social ul {
  display: inline-block;
  position: relative;
  left: -9px;
  margin-top: 15px;
}

.contact-us .contact-social ul {
  margin-left: 10px;
}

.contact-us .contact-social ul li {
  display: inline-block;
  margin-right: 6px;
}

.contact-us .contact-social ul li:last-child {
  margin-right: 0;
}

.contact-us .contact-social ul li a {
  font-size: 14px;
  color: #081828;
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  line-height: 40px;
  background: #e8ecf1;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 3;
}

.contact-us .contact-social ul li a:hover {
  color: #fff;
  background-color: crimson;
}

.contact-us .single-info .title {
  margin-bottom: 10px;
  font-weight: 500;
  color: #fff;
  font-size: 18px;
}

.contact-us .single-info .title span {
  display: block;
  font-size: 14px;
  margin-top: 8px;
  font-weight: 500;
}

.contact-us .form-main {
  padding: 50px 35px 50px 50px;
}

.contact-us .form .form-group {
  margin-bottom: 15px;
  display: block;
}

.contact-us .form .form-group input {
  height: 55px;
  line-height: 55px;
  width: 100%;
  border: 1px solid #e6e2f5;
  padding: 0px 20px;
  color: #333;
  border-radius: 0px;
  font-weight: 400;
  border-radius: 5px;
}

.contact-us .form .form-group textarea {
  height: 180px;
  width: 100%;
  border: 1px solid #e6e2f5;
  padding: 15px 20px;
  color: #333;
  border-radius: 0px;
  resize: none;
  font-weight: 400;
  border-radius: 5px;
}

.contact-us .form .button {
  margin: 0;
}

.contact-us .form .button .btn {
  height: 50px;
  border: none;
}

.map-section {
  background-color: #f6f9fc;
  margin-top: 50px;
  padding-bottom: 100px;
}

.map-section .map-container {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  padding: 20px;
  border-radius: 7px;
  background-color: #fff;
}

/*======================================
	Error 404 CSS
========================================*/
.error-area {
  height: 100vh;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  vertical-align: middle;
}

.d-table-cell {
  display: table-cell !important;
}

.error-area .error-content h1 {
  font-size: 120px;
  color: crimson;
  margin-bottom: 10px;
  font-weight: 700;
}

.error-area .error-content h2 {
  font-size: 25px;
  margin-bottom: 10px;
  color: #081828;
}

.error-area .error-content p {
  font-weight: 500;
  margin-bottom: 20px;
}

/*======================================
	Mail Success CSS
========================================*/
.maill-success {
  height: 100vh;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  vertical-align: middle;
}

.d-table-cell {
  display: table-cell !important;
}

.maill-success .success-content h1 {
  font-size: 40px;
  color: crimson;
  margin-bottom: 10px;
  font-weight: 700;
}

.maill-success .success-content h2 {
  font-size: 25px;
  margin-bottom: 10px;
  color: #081828;
}

.maill-success .success-content p {
  font-weight: 500;
  margin-bottom: 20px;
}

/*======================================
    Modal CSS
========================================*/
/* .modal { */
/* position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block; */
/* width: 100%;
  height: 100%; */
/* overflow: hidden;
  outline: 0; */
/* } */

.position-relative {
  position: relative !important;
}

.pos-abs-tr {
  position: absolute;
  top: 0;
  right: 0;
}

.z-index-supper {
  z-index: 800;
}

.circle-32 {
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 20px;
  top: 20px;
  background: #eee !important;
  border-radius: 3px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.circle-32:hover {
  background-color: #081828 !important;
  color: #fff !important;
}

.btn-reset {
  background: transparent;
  border: 0;
}

.modal.show .modal-dialog {
  pointer-events: visible;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.modal .login-modal-main {
  background-color: #fff;
  padding: 50px;
  border-radius: 6px;
  overflow: hidden;
}

.modal .login-modal-main .heading h3 {
  color: #081828;
  font-size: 26px;
  font-weight: 700;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  line-height: 32px;
}

.modal .login-modal-main .heading h3::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 50px;
  background-color: crimson;
}

.modal .login-modal-main .social-login {
  margin-top: 30px;
}

.modal .login-modal-main .social-login ul li {
  margin-bottom: 10px;
}

.modal .login-modal-main .social-login ul li:last-child {
  margin-bottom: 0;
}

.modal .login-modal-main .social-login ul li a {
  text-align: center;
  position: relative;
  background-color: crimson;
  padding: 13px 30px;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  z-index: 2;
}

.modal .login-modal-main .social-login ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: -1;
}

.modal .login-modal-main .social-login ul li a:hover::before {
  opacity: 0.2;
  visibility: visible;
}

.modal .login-modal-main .social-login ul li a i {
  position: absolute;
  left: 30px;
  top: 14px;
  font-size: 20px;
}

.modal .login-modal-main .social-login ul li .linkedin {
  background-color: #0077b5;
}

.modal .login-modal-main .social-login ul li .google {
  background-color: #dd4b39;
}

.modal .login-modal-main .social-login ul li .facebook {
  background-color: #3b5999;
}

.modal .login-modal-main .or-devider {
  text-align: center;
  position: relative;
  z-index: 1;
}

.modal .login-modal-main .or-devider span {
  display: inline-block;
  background: white;
  padding: 15px 25px;
}

.modal .login-modal-main .or-devider::before {
  content: "";
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0%;
  margin-top: 1.5px;
  position: absolute;
  background: #eee;
  z-index: -1;
}

.modal .login-modal-main .form-group {
  margin-bottom: 20px;
}

.modal .login-modal-main .form-group:last-child {
  margin-bottom: 0;
}

.modal .login-modal-main .form-group .label {
  font-size: 15px;
  font-weight: 500;
  color: #081828;
  display: block;
  margin-bottom: 8px;
}

.modal .login-modal-main .form-group .form-control {
  height: 50px;
  border: 1px solid #eee;
  padding: 0px 30px;
  color: #081828;
  border-radius: 5px;
  font-size: 15px;
}

.modal .login-modal-main .form-group .pass {
  padding-right: 50px;
}

.modal .login-modal-main .button {
  width: 100%;
}

.modal .login-modal-main .button .btn {
  width: 100%;
}

.modal .login-modal-main .create-new-account {
  color: #081828;
  font-size: 15px;
}

.modal .login-modal-main .create-new-account a {
  color: crimson;
}

.modal .login-modal-main .create-new-account a:hover {
  text-decoration: underline;
}

.pos-abs-cr {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  display: block;
  background-color: transparent;
  opacity: 1;
  visibility: visible;
  line-height: 30px;
  text-align: center;
  display: block;
}

.pos-abs-cr::before {
  content: "\eab1";
  font-family: Lineicons;
  color: #081828;
  font-size: 17px;
}

.pos-abs-cr::after {
  content: "";
  position: absolute;
  right: 5px;
  top: 14px;
  height: 2px;
  width: 20px;
  background-color: #555;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 0px;
  opacity: 0;
  visibility: hidden;
}

.pos-abs-cr.show::after {
  opacity: 1;
  visibility: visible;
}

/*======================================
	Footer CSS
========================================*/
.footer {
  background-color: #fff;
  z-index: 2;
  position: relative;
}

.footer .footer-top {
  border-bottom: 1px solid #eee;
  padding: 60px 0;
}

.footer .footer-top .download-text h3 {
  font-size: 30px;
  color: #081828;
  display: block;
  margin-bottom: 20px;
  font-weight: 700;
}

.footer .footer-top .download-button {
  text-align: right;
}

.footer .footer-top .download-button .btn {
  display: inline-block;
  margin-right: 10px;
}

.footer .footer-top .download-button .btn:last-child {
  margin-right: 0;
}

.footer .footer-top .download-button .btn i {
  font-size: 18px;
  display: inline-block;
  margin-right: 5px;
}

.footer .logo {
  margin-bottom: 26px;
}

.footer .logo img {
  width: 150px;
}

.footer .f-about p {
  font-size: 14px;
  line-height: 28px;
  color: #7e8890;
}

.footer .f-about .contact-address {
  margin-top: 20px;
}

.footer .f-about .contact-address li {
  display: block;
  margin-bottom: 5px;
}

.footer .f-about .contact-address li:last-child {
  margin-bottom: 0;
}

.footer .f-about .contact-address li span {
  font-weight: 500;
  color: #081828;
  display: inline-block;
  margin-right: 4px;
}

.footer .footer-social {
  margin-top: 35px;
}

.footer .footer-social ul li {
  display: inline-block;
  margin-right: 6px;
}

.footer .footer-social ul li:last-child {
  margin-right: 0;
}

.footer .footer-social ul li a {
  font-size: 14px;
  color: #081828;
  height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  line-height: 40px;
  background: #e8ecf1;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 3;
}

.footer .footer-social ul li a:hover {
  color: #fff;
  background-color: crimson;
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
}

.footer .footer-middle {
  padding-bottom: 70px;
  padding-top: 40px;
}

.footer .single-footer {
  margin-top: 30px;
}

.footer .single-footer h3 {
  color: #081828;
  font-size: 19px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
  margin-bottom: 30px;
  font-weight: 500;
}

.footer .f-link ul li {
  margin-bottom: 15px;
  position: relative;
}

.footer .f-link ul li:last-child {
  margin: 0;
}

.footer .f-link ul li a {
  display: inline-block;
  color: #7e8890;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer .f-link ul li a:hover {
  color: crimson;
  text-decoration: underline;
}

.footer .footer-bottom {
  border-top: 1px solid #eee;
}

.footer .footer-bottom .inner {
  padding: 30px 0;
  text-align: left;
}

.footer .footer-bottom .inner p {
  font-size: 14px;
  color: #7e8890;
}

.footer .footer-bottom .inner p a {
  font-weight: 400;
  display: inline-block;
  margin-left: 6px;
  color: #7e8890;
}

.footer .footer-bottom .inner p a:hover {
  color: crimson;
  text-decoration: underline;
}

.footer .footer-bottom .inner .right ul {
  text-align: right;
}

.footer .footer-bottom .inner .right ul li {
  display: inline-block;
  margin-right: 15px;
}

.footer .footer-bottom .inner .right ul li:last-child {
  margin: 0;
}

.footer .footer-bottom .inner .right ul li a {
  color: #7e8890;
}

.footer .footer-bottom .inner .right ul li a:hover {
  text-decoration: underline;
  color: crimson;
}

.footer .newsletter form {
  margin-top: 30px;
}

.footer .newsletter input {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #eee;
  color: #081828;
  padding: 0px 25px;
}

.footer .newsletter button {
  height: 50px;
  margin-top: 15px;
  width: 100%;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.isa_info,
.isa_success,
.isa_warning,
.isa_error {
  margin: 5px 0px;
  padding: 2px;
}
.isa_info {
  color: #00529b;
  background-color: #bde5f8;
}
.isa_success {
  color: #4f8a10;
  background-color: #dff2bf;
}
.isa_warning {
  color: #9f6000;
  background-color: #feefb3;
}
.isa_error {
  color: #d8000c;
  background-color: #ffd2d2;
}
.isa_info i,
.isa_success i,
.isa_warning i,
.isa_error i {
  margin: 5px 10px;
  font-size: 1.2em;
  vertical-align: middle;
}

.is_a_error {
  color: #d10000;
  background-color: #ffd2d2;
  padding: "3px 10px";
  border: "1px solid red";
  border-radius: "5px";
  margin-top: "5px";
}

.back {
  background-color: white;
  display: block;
}
